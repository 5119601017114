/*----------------------------------------------------


@Default Styles

Table of Content:
01/ Variables
02/ predefin
03/ header
04/ button
05/ banner
06/ breadcrumb
07/ about
08/ team
09/ project 
10/ price 
11/ team 
12/ blog 
13/ video  
14/ service  
15/ career  
16/ contact 
17/ footer

=====================================================================*/


/*----------------------------------------------------*/


/*font Variables*/


/*Color Variables*/


/*=================== fonts ====================*/

@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700,700i|Roboto:300,400,500,700");

/*---------------------------------------------------- */


/*----------------------------------------------------*/

.list {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

a {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: none;
}

.row.m0 {
    margin: 0px;
}

body {
    line-height: 24px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    color: #797979;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    color: #1a1d24;
}

button:focus {
    outline: none;
    box-shadow: none;
}

.p_120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.pad_top {
    padding-top: 120px;
}

.mt-25 {
    margin-top: 25px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

.bg-1 {
    background: #f7f7f7;
}

.pad-top-0 {
    padding-top: 0 !important;
}

img {
    max-width: 100%;
}

ul {
    list-style: none;
    padding: 0;
}

@media (min-width: 1620px) {
    .box_1620 {
        max-width: 1650px;
        margin: auto;
    }
}


/* Main Title Area css
============================================================================================ */

.main_title {
    text-align: center;
    max-width: 680px;
    margin: 0px auto 75px;
}

.main_title h2 {
    font-family: 'Crimson Text';
    font-size: 36px;
    color: #1a1d24;
    margin-bottom: 12px;
}

.main_title p {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    line-height: 24px;
    color: #797979;
    margin-bottom: 0px;
}


/* End Main Title Area css
============================================================================================ */


/*---------------------------------------------------- */


/*----------------------------------------------------*/

h5:first-child:first-letter {
    color: white;
    float: left;
    font-family: Georgia;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
}

.header_area {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    transition: background 0.4s, all 0.3s linear;
}

.header_area .navbar {
    background: transparent;
    padding: 0px;
    border: 0px;
    border-radius: 0px;
}

.header_area .navbar .nav .nav-item {
    margin-right: 55px;
    position: relative;
}

.header_area .navbar .nav .nav-item .nav-link {
    font: 500 12px/80px "Roboto", sans-serif;
    text-transform: uppercase;
    color: #1a1d24;
    padding: 0px;
    display: inline-block;
    font-size: 14px;
}

.header_area .navbar .nav .nav-item .nav-link:after {
    display: none;
}

.header_area .navbar .nav .nav-item:hover .nav-link,
.header_area .navbar .nav .nav-item.active .nav-link {
    color: #f81c1c;
}

.header_area .navbar .nav .nav-item.submenu {
    position: relative;
}

.header_area .navbar .nav .nav-item.submenu ul {
    border: none;
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
    margin: 0px;
    background: #fff;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
    .header_area .navbar .nav .nav-item.submenu ul {
        position: absolute;
        top: 120%;
        left: 0px;
        min-width: 200px;
        text-align: left;
        opacity: 0;
        transition: all 300ms ease-in;
        visibility: hidden;
        display: block;
        border: none;
        padding: 0px;
        border-radius: 0px;
    }
}

.header_area .navbar .nav .nav-item.submenu ul:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #eeeeee transparent transparent transparent;
    position: absolute;
    right: 24px;
    top: 45px;
    z-index: 3;
    opacity: 0;
    transition: all 400ms linear;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item {
    display: block;
    float: none;
    margin-right: 0px;
    border-bottom: 1px solid #ededed;
    margin-left: 0px;
    transition: all 0.4s linear;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
    line-height: 45px;
    color: #1a1d24;
    padding: 0px 30px;
    transition: all 150ms linear;
    display: block;
    margin-right: 0px;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
    border-bottom: none;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
    background: #f81c1c;
    color: #fff;
}

@media (min-width: 992px) {
    .header_area .navbar .nav .nav-item.submenu:hover ul {
        visibility: visible;
        opacity: 1;
        top: 100%;
    }
}

.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
    margin-top: 0px;
}

.header_area .navbar .nav .nav-item:last-child {
    margin-right: 0px;
}

.header_area .navbar .nav .nav-item:last-child:after {
    display: none;
}

.header_area .navbar .nav>.nav-item:after {
    content: '/';
    position: absolute;
    right: -23px;
    top: 28px;
    font-family: "Roboto", sans-serif;
    font-size: 19px;
    transform: rotate(-6deg);
}

.header_area .navbar .nav>.nav-item:before {
    content: '';
    position: absolute;
    right: 0px;
    top: 39px;
    left: 100%;
    height: 2px;
    background: #f81c1c;
    z-index: -1;
}

.header_area .navbar .nav>.nav-item:hover:before {
    left: -22px;
    transition: all 300ms linear 0s;
}

.header_area .navbar .nav>.nav-item.active:before {
    left: -22px;
    transition: all 300ms linear 0s;
}

.header_area.navbar_fixed .main_menu {
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    background: #fff;
    transform: translateY(70px);
    transition: transform 500ms ease, background 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}

.header_area .right-button {
    margin-left: 80px;
    margin-top: 18px;
}

.header_area .right-button ul li a {
    color: #1a1d24;
    font-size: 14px;
}

#search_input_box {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    max-width: 1200px;
    z-index: 999;
    text-align: center;
    padding: 0 20px;
    display: none;
}

#search_input_box ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #fff;
}

#search_input_box ::-moz-placeholder {
    /* Firefox 19+ */
    color: #fff;
}

#search_input_box :-ms-input-placeholder {
    /* IE 10+ */
    color: #fff;
}

#search_input_box :-moz-placeholder {
    /* Firefox 18- */
    color: #fff;
}

#search_input_box {
    background: transparent;
    border: 0;
    color: #ffffff;
    font-weight: 400;
    font-size: 15px;
    padding: 0;
}

#search_input_box .btn {
    width: 0;
    height: 0;
    padding: 0;
    border: 0;
}

#search_input_box .ti-close {
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    padding: 10px;
}

.search-inner {
    background: #f81c1c;
    padding: 5px 15px;
}

.form-control:focus {
    box-shadow: none;
}

.navbar_fixed #search_input_box {
    position: fixed;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 100%;
    max-width: 1200px;
    z-index: 999;
    text-align: center;
    padding: 0 20px;
    top: 80px;
}

.card-priceTable {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    transition: all 300ms linear 0s;
}

.card-priceTable:hover .main_btn {
    background: #f81c1c;
    color: #fff;
    border-color: #f81c1c;
}

.priceTable-header {
    padding: 25px 9px;
    background: #f7f7f7;
    margin-bottom: 10px;
    transition: all 300ms linear 0s;
}

@media (min-width: 768px) {
    .priceTable-header {
        padding: 45px 10px 30px 10px;
    }
}

.priceTable-header h3 {
    font-size: 27px;
    color: #1a1d24;
}

.priceTable-header h1 {
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    color: #1a1d24;
}

.priceTable-header p {
    color: #777;
}

.priceTable-price {
    font-size: 22px;
}

@media (min-width: 768px) {
    .priceTable-price {
        font-size: 60px;
    }
}

.priceTable-price span {
    font-size: 22px;
    display: inline-block;
}

@media (min-width: 768px) {
    .priceTable-price span {
        font-size: 40px;
        position: relative;
        bottom: 7px;
        left: 11px;
    }
}

.priceTable-list {
    padding: 30px 10px 0;
    background: #f7f7f7;
    margin: 0;
    transition: all 300ms linear 0s;
}

.priceTable-list li {
    margin-bottom: 13px;
    transition: all 300ms linear 0s;
}

.priceTable-list li:last-child {
    margin-bottom: 0;
}

.priceTable-list li i,
.priceTable-list li span {
    font-size: 13px;
    margin-right: 3px;
}

.priceTable-list li i.positive,
.priceTable-list li span.positive {
    color: #797979;
}

.priceTable-footer {
    padding: 25px 9px;
    background: #f7f7f7;
}

@media (min-width: 768px) {
    .priceTable-footer {
        padding: 45px 0;
    }
}

.priceTable-footer .main_btn {
    padding: 0px 50px;
    font-weight: 500;
}


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/* Home Banner Area css
============================================================================================ */

.home_banner_area {
    position: relative;
    z-index: 1;

}

.home_banner_area .banner_inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: auto;
    margin-top: 150px;
    padding-bottom: 150px;
    padding-top: 170px;
}

.home_banner_area .banner_inner .overlay {
    /* background: url(../../img/home/mountain.jpg) no-repeat scroll center center; */
    filter: blur(4px);
    -webkit-animation: 3s linear forwards reveal;
     -moz-animation: 3s linear forwards reveal;
       -o-animation: 3s linear forwards reveal;
          animation: 3s linear forwards reveal;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

@-webkit-keyframes reveal { from { filter:blur(5px); } to { filter:blur(0px); }  }
   @-moz-keyframes reveal { from { filter:blur(5px); } to { filter:blur(0px); }  }
     @-o-keyframes reveal { from { filter:blur(5px); } to { filter:blur(0px); }  }
        @keyframes reveal { from { filter:blur(5px); } to { filter:blur(0px); }  }


@media (min-width: 992px) and (max-width: 1199px) {
    .home_banner_area .banner_inner .overlay {
        background-position: center center;
    }
}

.home_banner_area .banner_inner .banner_content {
    color: #fff;
    text-align: left;
    margin-top: 350px;
}

.home_banner_area .banner_inner .banner_content p {
    color: #1a1d24;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 35px;
}

@media (max-width: 767px) {
    .home_banner_area .banner_inner .banner_content p {
        margin-bottom: 20px;
    }
}

.home_banner_area .banner_inner .banner_content h1 {
    font-size: 48px;
    font-family: "Playfair Display", serif;
    font-weight: 700;
    text-align: left;
    color: #1a1d24;
    margin-bottom: 20px;
}

.home_banner_area .banner_inner .banner_content h1 .basecolor {
    color: #f81c1c;
}

@media (max-width: 767px) {
    .home_banner_area .banner_inner .banner_content h1 {
        font-size: 35px;
    }
}

@media (max-width: 1500px) {
    .home_banner_area .banner_inner .banner_content {
        margin-top: 0;
    }
}

@media (max-width: 1200px) {
    .home_banner_area .banner_inner .banner_content {
        padding-left: 50px;
    }
}

.home_banner_area .social_area {
    position: absolute;
    top: 300px;
    left: 90px;
}

.home_banner_area .social_area h4 {
    display: block;
}

.home_banner_area .social_area a {
    font-size: 14px;
    color: #1a1d24;
    font-family: "Roboto", sans-serif;
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: block;
    height: 108px;
}

.home_banner_area .social_area a i {
    font-size: 14px;
    margin-right: 10px;
}

.home_banner_area .social_area .round {
    width: 7px;
    height: 7px;
    background: #f81c1c;
    border-radius: 50px;
    transform: translateX(-5px);
}

@media (max-width: 1400px) {
    .home_banner_area .social_area {
        left: 60px;
    }
}

@media (max-width: 1200px) {
    .home_banner_area .social_area {
        left: 25px;
        top: 187px;
    }
}

@media (max-width: 768px) {
    .home_banner_area .social_area {
        display: none;
    }
}

.blog_banner {
    min-height: 660px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 0px;
}

.blog_banner .banner_inner {
    background: #04091e;
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 660px;
    z-index: 1;
}

.blog_banner .banner_inner .overlay {
    /* background: url(../img/banner/banner-2.jpg) no-repeat scroll center center; */
    opacity: .5;
    height: 125%;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: -1;
}

.blog_banner .banner_inner .blog_b_text {
    max-width: 700px;
    margin: auto;
    color: #fff;
}

.blog_banner .banner_inner .blog_b_text h2 {
    font-size: 60px;
    font-weight: bold;
    font-family: "Playfair Display", serif;
    line-height: 66px;
    margin-bottom: 15px;
}

.blog_banner .banner_inner .blog_b_text p {
    font-size: 16px;
    margin-bottom: 35px;
}

.blog_banner .banner_inner .blog_b_text .white_bg_btn {
    line-height: 42px;
    padding: 0px 45px;
}

.banner_box {
    max-width: 1620px;
    margin: auto;
}

.banner_area {
    position: relative;
    z-index: 1;
    min-height: 300px;
}

.banner_area .banner_inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 300px;
    background: #04091e;
    z-index: 1;
}

.banner_area .banner_inner .overlay {
    /* background: url(../img/banner/banner.jpg) no-repeat scroll center center; */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 105%;
    bottom: 0;
    z-index: -1;
    opacity: .6;
}

.banner_area .banner_inner .banner_content h2 {
    color: #fff;
    font-size: 48px;
    font-family: "Playfair Display", serif;
    margin-bottom: 0px;
    font-weight: bold;
}

.banner_area .banner_inner .banner_content .page_link a {
    font-size: 14px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    margin-right: 32px;
    position: relative;
}

.banner_area .banner_inner .banner_content .page_link a:before {
    content: "\e87a";
    font-family: 'Linearicons-Free';
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
}

.banner_area .banner_inner .banner_content .page_link a:last-child {
    margin-right: 0px;
}

.banner_area .banner_inner .banner_content .page_link a:last-child:before {
    display: none;
}

.banner_area .banner_inner .banner_content .page_link a:hover {
    color: #f81c1c;
}


/* End Home Banner Area css
============================================================================================ */

.about-banner {
    position: relative;
    padding: 205px 0 150px !important;
    background: url(../../img/about/about-banner.jpg) no-repeat scroll center center;
    background-size: cover;
}

@media (min-width: 768px) {
    .about-banner {
        padding: 150px 0;
    }
}

@media (min-width: 1200px) {
    .about-banner {
        padding: 110px 0;
    }
}

.hero-banner-sm {
    padding-top: 80px;
    padding-bottom: 80px;
}

@media (min-width: 992px) {
    .hero-banner-sm {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.about-banner h1 {
    color: #2a2a2a;
    margin-bottom: 25px;
    font-size: 28px;
}

@media (min-width: 768px) {
    .about-banner h1 {
        font-size: 60px;
        margin-bottom: 45px;
    }
}

.about-banner h2 {
    font-size: 35px;
    color: #2a2a2a;
    text-transform: capitalize;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .about-banner h2 {
        font-size: 42px;
    }
}

.about-banner p {
    font-size: 22px;
    color: #2a2a2a;
    margin-bottom: 0;
}

.hero-banner-icon {
    color: #2a2a2a;
    display: inline-block;
}

@media (min-width: 768px) {
    .hero-banner-icon {
        margin-bottom: 10px;
    }
}

.hero-banner-icon i,
.hero-banner-icon span {
    font-size: 35px;
}

@media (min-width: 768px) {
    .hero-banner-icon i,
    .hero-banner-icon span {
        font-size: 60px;
    }
}

.hero-banner-icon [class^="flaticon-"]:before,
.hero-banner-icon [class*=" flaticon-"]:before,
.hero-banner-icon [class^="flaticon-"]:after,
.hero-banner-icon [class*=" flaticon-"]:after {
    font-size: 35px;
}

@media (min-width: 768px) {
    .hero-banner-icon [class^="flaticon-"]:before,
    .hero-banner-icon [class*=" flaticon-"]:before,
    .hero-banner-icon [class^="flaticon-"]:after,
    .hero-banner-icon [class*=" flaticon-"]:after {
        font-size: 60px;
    }
}

.blogs-banner {
    position: relative;
    padding: 205px 0 150px !important;
    background: url(../../img/blogs/blogs-banner.jpg) no-repeat scroll center center;
    background-size: cover;
}

@media (min-width: 768px) {
    .blogs-banner {
        padding: 150px 0;
    }
}

@media (min-width: 1200px) {
    .blogs-banner {
        padding: 110px 0;
    }
}

.hero-banner-sm {
    padding-top: 80px;
    padding-bottom: 80px;
}

@media (min-width: 992px) {
    .hero-banner-sm {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.blogs-banner h1 {
    color: #2a2a2a;
    margin-bottom: 25px;
    font-size: 28px;
}

@media (min-width: 768px) {
    .blogs-banner h1 {
        font-size: 60px;
        margin-bottom: 45px;
    }
}

.blogs-banner h2 {
    font-size: 35px;
    color: #2a2a2a;
    text-transform: capitalize;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .blogs-banner h2 {
        font-size: 42px;
    }
}

.blogs-banner p {
    font-size: 22px;
    color: #2a2a2a;
    margin-bottom: 0;
}

.hero-banner-icon {
    color: #2a2a2a;
    display: inline-block;
}

@media (min-width: 768px) {
    .hero-banner-icon {
        margin-bottom: 10px;
    }
}

.hero-banner-icon i,
.hero-banner-icon span {
    font-size: 35px;
}

@media (min-width: 768px) {
    .hero-banner-icon i,
    .hero-banner-icon span {
        font-size: 60px;
    }
}

.hero-banner-icon [class^="flaticon-"]:before,
.hero-banner-icon [class*=" flaticon-"]:before,
.hero-banner-icon [class^="flaticon-"]:after,
.hero-banner-icon [class*=" flaticon-"]:after {
    font-size: 35px;
}

@media (min-width: 768px) {
    .hero-banner-icon [class^="flaticon-"]:before,
    .hero-banner-icon [class*=" flaticon-"]:before,
    .hero-banner-icon [class^="flaticon-"]:after,
    .hero-banner-icon [class*=" flaticon-"]:after {
        font-size: 60px;
    }
}

.contact-banner {
    position: relative;
    padding: 205px 0 150px !important;
    background: url(../../img/contact/contactUs.jpg) no-repeat scroll center center;
    background-size: cover;
}

@media (min-width: 768px) {
    .contact-banner {
        padding: 150px 0;
    }
}

@media (min-width: 1200px) {
    .contact-banner {
        padding: 110px 0;
    }
}

.hero-banner-sm {
    padding-top: 80px;
    padding-bottom: 80px;
}

@media (min-width: 992px) {
    .hero-banner-sm {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.contact-banner h1 {
    color: #2a2a2a;
    margin-bottom: 25px;
    font-size: 28px;
}

@media (min-width: 768px) {
    .contact-banner h1 {
        font-size: 60px;
        margin-bottom: 45px;
    }
}

.contact-banner h2 {
    font-size: 35px;
    color: #2a2a2a;
    text-transform: capitalize;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .contact-banner h2 {
        font-size: 42px;
    }
}

.contact-banner p {
    font-size: 22px;
    color: #2a2a2a;
    margin-bottom: 0;
}

.hero-banner-icon {
    color: #2a2a2a;
    display: inline-block;
}

@media (min-width: 768px) {
    .hero-banner-icon {
        margin-bottom: 10px;
    }
}

.hero-banner-icon i,
.hero-banner-icon span {
    font-size: 35px;
}

@media (min-width: 768px) {
    .hero-banner-icon i,
    .hero-banner-icon span {
        font-size: 60px;
    }
}

.hero-banner-icon [class^="flaticon-"]:before,
.hero-banner-icon [class*=" flaticon-"]:before,
.hero-banner-icon [class^="flaticon-"]:after,
.hero-banner-icon [class*=" flaticon-"]:after {
    font-size: 35px;
}

@media (min-width: 768px) {
    .hero-banner-icon [class^="flaticon-"]:before,
    .hero-banner-icon [class*=" flaticon-"]:before,
    .hero-banner-icon [class^="flaticon-"]:after,
    .hero-banner-icon [class*=" flaticon-"]:after {
        font-size: 60px;
    }
}

.hero-banner {
    position: relative;
    padding: 205px 0 150px !important;
    /* background: url(../../img/blogs/SettingIntentions/blog-1.jpg) no-repeat scroll center center; */
    background-size: cover;
}

@media (min-width: 768px) {
    .hero-banner {
        padding: 150px 0;
    }
}

@media (min-width: 1200px) {
    .hero-banner {
        padding: 110px 0;
    }
}

.hero-banner-sm {
    padding-top: 80px;
    padding-bottom: 80px;
}

@media (min-width: 992px) {
    .hero-banner-sm {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.hero-banner h1 {
    color: #2a2a2a;
    margin-bottom: 25px;
    font-size: 28px;
}

@media (min-width: 768px) {
    .hero-banner h1 {
        font-size: 60px;
        margin-bottom: 45px;
    }
}

.hero-banner h2 {
    font-size: 35px;
    color: #2a2a2a;
    text-transform: capitalize;
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .hero-banner h2 {
        font-size: 42px;
    }
}

.hero-banner p {
    font-size: 22px;
    color: #2a2a2a;
    margin-bottom: 0;
}

.hero-banner-icon {
    color: #2a2a2a;
    display: inline-block;
}

@media (min-width: 768px) {
    .hero-banner-icon {
        margin-bottom: 10px;
    }
}

.hero-banner-icon i,
.hero-banner-icon span {
    font-size: 35px;
}

@media (min-width: 768px) {
    .hero-banner-icon i,
    .hero-banner-icon span {
        font-size: 60px;
    }
}

.hero-banner-icon [class^="flaticon-"]:before,
.hero-banner-icon [class*=" flaticon-"]:before,
.hero-banner-icon [class^="flaticon-"]:after,
.hero-banner-icon [class*=" flaticon-"]:after {
    font-size: 35px;
}

@media (min-width: 768px) {
    .hero-banner-icon [class^="flaticon-"]:before,
    .hero-banner-icon [class*=" flaticon-"]:before,
    .hero-banner-icon [class^="flaticon-"]:after,
    .hero-banner-icon [class*=" flaticon-"]:after {
        font-size: 60px;
    }
}



/* End Causes Area css
============================================================================================ */


/*================= latest_blog_area css =============*/

.latest_blog_area {
    background: #f9f9ff;
}

.single-recent-blog-post {
    margin-bottom: 30px;
}

.single-recent-blog-post .thumb {
    overflow: hidden;
}

.single-recent-blog-post .thumb img {
    transition: all 0.7s linear;
}

.single-recent-blog-post .details {
    padding-top: 30px;
}

.single-recent-blog-post .details .sec_h4 {
    line-height: 24px;
    padding: 10px 0px 13px;
    transition: all 0.3s linear;
}

.single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.single-recent-blog-post:hover img {
    transform: scale(1.23) rotate(10deg);
}

.tags .tag_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #eeeeee;
    display: inline-block;
    padding: 1px 18px;
    text-align: center;
    color: #2a2a2a;
}

.tags .tag_btn:before {
    background: #2a2a2a;
}

.tags .tag_btn+.tag_btn {
    margin-left: 2px;
}


/*========= blog_categorie_area css ===========*/

.blog_categorie_area {
    padding-top: 30px;
    padding-bottom: 30px;
}

@media (min-width: 900px) {
    .blog_categorie_area {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

@media (min-width: 1100px) {
    .blog_categorie_area {
        padding-top: 120px;
        padding-bottom: 120px;
    }
}

.categories_post {
    position: relative;
    text-align: center;
    cursor: pointer;
}

.categories_post img {
    max-width: 100%;
}

.categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(34, 34, 34, 0.75);
    color: #fff;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center;
}

.categories_post .categories_details h5 {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
    position: relative;
}

.categories_post .categories_details p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px;
}

.categories_post .categories_details .border_line {
    margin: 10px 0px;
    background: #fff;
    width: 100%;
    height: 1px;
}

.categories_post:hover .categories_details {
    background: rgba(222, 99, 32, 0.85);
}


/*============ blog_left_sidebar css ==============*/

.blog_item {
    margin-bottom: 50px;
}

.blog_details {
    padding: 30px 0 20px 10px;
    box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.5);
}

@media (min-width: 768px) {
    .blog_details {
        padding: 60px 30px 35px 35px;
    }
}

.blog_details p {
    margin-bottom: 30px;
}

.blog_details a {
    color: #2a2a2a;
}

.blog_details a:hover {
    color: #f81c1c;
}

.blog_details h2 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
}

@media (min-width: 768px) {
    .blog_details h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }
}

.blog-info-link li {
    float: left;
    font-size: 14px;
}

.blog-info-link li a {
    color: #999999;
}

.blog-info-link li i,
.blog-info-link li span {
    font-size: 13px;
    margin-right: 5px;
}

.blog-info-link li::after {
    content: "|";
    padding-left: 10px;
    padding-right: 10px;
}

.blog-info-link li:last-child::after {
    display: none;
}

.blog-info-link::after {
    content: "";
    display: block;
    clear: both;
    display: table;
}

.blog_item_img {
    position: relative;
}

.blog_item_img .blog_item_date {
    position: absolute;
    bottom: 0px;
    left: 0px;
    display: block;
    color: #fff;
    background: #00B193;
    padding: 8px 15px;
    border-radius: 5px;
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date {
        bottom: 0px;
        left: 0px;
        padding: 13px 30px;
    }
}

.blog_item_img .blog_item_date h3 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    line-height: 1.2;
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date h3 {
        font-size: 30px;
    }
}

.blog_item_img .blog_item_date p {
    margin-bottom: 0;
}

@media (min-width: 768px) {
    .blog_item_img .blog_item_date p {
        font-size: 18px;
    }
}

.blog_right_sidebar .widget_title {
    font-size: 20px;
    margin-bottom: 40px;
    color: #2a2a2a;
}

.blog_right_sidebar .widget_title::after {
    content: "";
    display: block;
    padding-top: 15px;
    border-bottom: 1px solid #f0e9ff;
}

.blog_right_sidebar .single_sidebar_widget {
    background: #fbf9ff;
    padding: 30px;
    margin-bottom: 30px;
}

.blog_right_sidebar .search_widget .form-control {
    height: 50px;
    border-color: blue;
    font-size: 13px;
    color: blue;
    padding-left: 20px;
    border-radius: 0;
    border-right: 0;
}

.blog_right_sidebar .search_widget .form-control::placeholder {
    color: blue;
}

.blog_right_sidebar .search_widget .form-control:focus {
    border-color: blue;
    outline: 0;
    box-shadow: none;
}

.blog_right_sidebar .search_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0;
}

.blog_right_sidebar .search_widget .input-group button i,
.blog_right_sidebar .search_widget .input-group button span {
    font-size: 14px;
    color: blue;
}

.blog_right_sidebar .newsletter_widget .form-control {
    height: 50px;
    border-color: blue;
    font-size: 13px;
    color: blue;
    padding-left: 20px;
    border-radius: 0;
}

.blog_right_sidebar .newsletter_widget .form-control::placeholder {
    color: blue;
}

.blog_right_sidebar .newsletter_widget .form-control:focus {
    border-color: blue;
    outline: 0;
    box-shadow: none;
}

.blog_right_sidebar .newsletter_widget .input-group button {
    background: #fff;
    border-left: 0;
    border: 1px solid #f0e9ff;
    padding: 4px 15px;
    border-left: 0;
}

.blog_right_sidebar .newsletter_widget .input-group button i,
.blog_right_sidebar .newsletter_widget .input-group button span {
    font-size: 14px;
    color: #999999;
}

.blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 1px solid #f0e9ff;
    transition: all 0.3s ease 0s;
    padding-bottom: 12px;
}

.blog_right_sidebar .post_category_widget .cat-list li:last-child {
    border-bottom: 0;
}

.blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #888888;
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px;
}

.blog_right_sidebar .post_category_widget .cat-list li+li {
    padding-top: 15px;
}

.blog_right_sidebar .post_category_widget .cat-list li:hover a {
    color: #f81c1c;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    transition: all 0.3s linear;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body a {
    color: #2a2a2a;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body a:hover {
    color: #f81c1c;
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0px;
}

.blog_right_sidebar .popular_post_widget .post_item+.post_item {
    margin-top: 20px;
}

.blog_right_sidebar .tag_cloud_widget ul li {
    display: inline-block;
}

.blog_right_sidebar .tag_cloud_widget ul li a {
    display: inline-block;
    border: 1px solid #eeeeee;
    background: #fff;
    padding: 4px 20px;
    margin-bottom: 8px;
    margin-right: 5px;
    transition: all 0.3s ease 0s;
    color: #888888;
    font-size: 13px;
}

.blog_right_sidebar .tag_cloud_widget ul li a:hover {
    background: #f81c1c;
    color: #fff;
}

.blog_right_sidebar .instagram_feeds .instagram_row {
    display: flex;
    margin-right: -6px;
    margin-left: -6px;
}

.blog_right_sidebar .instagram_feeds .instagram_row li {
    width: 33.33%;
    float: left;
    padding-right: 6px;
    padding-left: 6px;
    margin-bottom: 15px;
}

.blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #eeeeee;
    margin: 30px 0px;
}

.blog-pagination {
    margin-top: 80px;
}

.blog-pagination .page-link {
    font-size: 14px;
    position: relative;
    display: block;
    padding: 0;
    text-align: center;
    margin-left: -1px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    border-radius: 0 !important;
    color: #8a8a8a;
    border: 1px solid #f0e9ff;
    margin-right: 10px;
}

.blog-pagination .page-link i,
.blog-pagination .page-link span {
    font-size: 13px;
}

.blog-pagination .page-link:hover {
    background-color: #f81c1c;
    color: #fff;
}

.blog-pagination .page-item.active .page-link {
    background-color: #fbf9ff;
    border-color: #f0e9ff;
    color: #888888;
}

.blog-pagination .page-item:last-child .page-link {
    margin-right: 0;
}


/*============ Start Blog Single Styles  =============*/

.single-post-area .blog_details {
    box-shadow: none;
    padding: 0;
}

.single-post-area .social-links {
    padding-top: 10px;
}

.single-post-area .social-links li {
    display: inline-block;
    margin-bottom: 10px;
}

.single-post-area .social-links li a {
    color: #cccccc;
    padding: 7px;
    font-size: 14px;
    transition: all 0.2s linear;
}

.single-post-area .social-links li a:hover {
    color: #2a2a2a;
}

.single-post-area .blog_details {
    padding-top: 26px;
}

.single-post-area .blog_details p {
    margin-bottom: 20px;
    font-size: 15px;
}

.single-post-area .blog_details h2 {
    color: #2a2a2a;
}

.single-post-area .quote-wrapper {
    background: rgba(130, 139, 178, 0.1);
    padding: 15px;
    line-height: 1.733;
    color: #888888;
    font-style: italic;
    margin-top: 25px;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .single-post-area .quote-wrapper {
        padding: 30px;
    }
}

.single-post-area .quotes {
    background: #fff;
    padding: 15px 15px 15px 20px;
    border-left: 2px solid #f81c1c;
}

@media (min-width: 768px) {
    .single-post-area .quotes {
        padding: 25px 25px 25px 30px;
    }
}

.single-post-area .arrow {
    position: absolute;
}

.single-post-area .arrow .lnr {
    font-size: 20px;
    font-weight: 600;
}

.single-post-area .thumb .overlay-bg {
    background: rgba(0, 0, 0, 0.8);
}

.single-post-area .navigation-top {
    padding-top: 15px;
    border-top: 1px solid #f0e9ff;
}

.single-post-area .navigation-top p {
    margin-bottom: 0;
}

.single-post-area .navigation-top .like-info {
    font-size: 14px;
}

.single-post-area .navigation-top .like-info i,
.single-post-area .navigation-top .like-info span {
    font-size: 16px;
    margin-right: 5px;
}

.single-post-area .navigation-top .comment-count {
    font-size: 14px;
}

.single-post-area .navigation-top .comment-count i,
.single-post-area .navigation-top .comment-count span {
    font-size: 16px;
    margin-right: 5px;
}

.single-post-area .navigation-top .social-icons li {
    display: inline-block;
    margin-right: 15px;
}

.single-post-area .navigation-top .social-icons li:last-child {
    margin: 0;
}

.single-post-area .navigation-top .social-icons li i,
.single-post-area .navigation-top .social-icons li span {
    font-size: 14px;
    color: #999999;
}

.single-post-area .navigation-top .social-icons li:hover i,
.single-post-area .navigation-top .social-icons li:hover span {
    color: #f81c1c;
}

.single-post-area .blog-author {
    padding: 40px 30px;
    background: #fbf9ff;
    margin-top: 50px;
}

@media (max-width: 600px) {
    .single-post-area .blog-author {
        padding: 20px 8px;
    }
}

.single-post-area .blog-author img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 30px;
}

@media (max-width: 600px) {
    .single-post-area .blog-author img {
        margin-right: 15px;
        width: 45px;
        height: 45px;
    }
}

.single-post-area .blog-author a {
    display: inline-block;
    color: #2a2a2a;
}

.single-post-area .blog-author a:hover {
    color: #f81c1c;
}

.single-post-area .blog-author p {
    margin-bottom: 0;
    font-size: 15px;
}

.single-post-area .blog-author h4 {
    font-size: 16px;
}

.single-post-area .navigation-area {
    border-bottom: 1px solid #eee;
    padding-bottom: 30px;
    margin-top: 55px;
}

.single-post-area .navigation-area p {
    margin-bottom: 0px;
}

.single-post-area .navigation-area h4 {
    font-size: 18px;
    line-height: 25px;
    color: #2a2a2a;
}

.single-post-area .navigation-area .nav-left {
    text-align: left;
}

.single-post-area .navigation-area .nav-left .thumb {
    margin-right: 20px;
    background: #000;
}

.single-post-area .navigation-area .nav-left .thumb img {
    transition: all 300ms linear 0s;
}

.single-post-area .navigation-area .nav-left .lnr {
    margin-left: 20px;
    opacity: 0;
    transition: all 300ms linear 0s;
}

.single-post-area .navigation-area .nav-left:hover .lnr {
    opacity: 1;
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
    opacity: .5;
}

@media (max-width: 767px) {
    .single-post-area .navigation-area .nav-left {
        margin-bottom: 30px;
    }
}

.single-post-area .navigation-area .nav-right {
    text-align: right;
}

.single-post-area .navigation-area .nav-right .thumb {
    margin-left: 20px;
    background: #000;
}

.single-post-area .navigation-area .nav-right .thumb img {
    transition: all 300ms linear 0s;
}

.single-post-area .navigation-area .nav-right .lnr {
    margin-right: 20px;
    opacity: 0;
    transition: all 300ms linear 0s;
}

.single-post-area .navigation-area .nav-right:hover .lnr {
    opacity: 1;
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
    opacity: .5;
}

@media (max-width: 991px) {
    .single-post-area .sidebar-widgets {
        padding-bottom: 0px;
    }
}

.comments-area {
    background: transparent;
    border-top: 1px solid #eee;
    padding: 45px 0;
    margin-top: 50px;
}

@media (max-width: 414px) {
    .comments-area {
        padding: 50px 8px;
    }
}

.comments-area h4 {
    margin-bottom: 35px;
    color: #2a2a2a;
    font-size: 18px;
}

.comments-area h5 {
    font-size: 16px;
    margin-bottom: 0px;
}

.comments-area a {
    color: #2a2a2a;
}

.comments-area .comment-list {
    padding-bottom: 48px;
}

.comments-area .comment-list:last-child {
    padding-bottom: 0px;
}

.comments-area .comment-list.left-padding {
    padding-left: 25px;
}

@media (max-width: 413px) {
    .comments-area .comment-list .single-comment h5 {
        font-size: 12px;
    }
    .comments-area .comment-list .single-comment .date {
        font-size: 11px;
    }
    .comments-area .comment-list .single-comment .comment {
        font-size: 10px;
    }
}

.comments-area .thumb {
    margin-right: 20px;
}

.comments-area .thumb img {
    width: 70px;
    border-radius: 50%;
}

.comments-area .date {
    font-size: 14px;
    color: #999999;
    margin-bottom: 0;
    margin-left: 20px;
}

.comments-area .comment {
    margin-bottom: 10px;
    color: #777777;
    font-size: 15px;
}

.comments-area .btn-reply {
    background-color: transparent;
    color: #888888;
    padding: 5px 18px;
    font-size: 14px;
    display: block;
    font-weight: 400;
}

.comment-form {
    border-top: 1px solid #eee;
    padding-top: 45px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.comment-form .form-group {
    margin-bottom: 30px;
}

.comment-form h4 {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 22px;
    color: #2a2a2a;
}

.comment-form .name {
    padding-left: 0px;
}

@media (max-width: 767px) {
    .comment-form .name {
        padding-right: 0px;
        margin-bottom: 1rem;
    }
}

.comment-form .email {
    padding-right: 0px;
}

@media (max-width: 991px) {
    .comment-form .email {
        padding-left: 0px;
    }
}

.comment-form .form-control {
    border: 1px solid blue;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent;
}

.comment-form .form-control:focus {
    outline: 0;
    box-shadow: none;
}

.comment-form .form-control::placeholder {
    font-weight: 300;
    color: blue;
}

.comment-form .form-control::placeholder {
    color: blue;
}

.comment-form textarea {
    padding-top: 18px;
    border-radius: 12px;
    height: 100% !important;
}

.comment-form ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 13px;
    color: #777;
}

.comment-form ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 13px;
    color: #777;
}

.comment-form :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 13px;
    color: #777;
}

.comment-form :-moz-placeholder {
    /* Firefox 18- */
    font-size: 13px;
    color: #777;
}


/*============ End Blog Single Styles  =============*/


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/*=================== contact banner start ====================*/

.contact-info {
    margin-bottom: 12px;
}

.contact-info__icon {
    margin-right: 20px;
}

.contact-info__icon i,
.contact-info__icon span {
    color: #8f9195;
    font-size: 27px;
}

.contact-info .media-body h3 {
    font-size: 16px;
    margin-bottom: 0;
    color: #2a2a2a;
}

.contact-info .media-body h3 a {
    color: #2a2a2a;
}

.contact-info .media-body h3 a:hover {
    color: #f81c1c;
}

.contact-info .media-body p {
    color: #999999;
}


/*=================== contact banner end ====================*/


/*=================== contact form start ====================*/

.contact-title {
    font-size: 27px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #2a2a2a;
}

.form-contact label {
    color: #f81c1c;
    font-size: 14px;
}

.form-contact .form-group {
    margin-bottom: 30px;
}

.form-contact .form-control {
    border: 1px solid #00b192;
    border-radius: 5px;
    height: 48px;
    padding-left: 18px;
    font-size: 13px;
    background: transparent;
}

.form-contact .form-control:focus {
    outline: 0;
    box-shadow: none;
}

.form-contact .form-control::placeholder {
    font-weight: 300;
    color: #00b192;
}

.form-contact textarea {
    border-radius: 12px;
    height: 100% !important;
}


/*=================== contact form end ====================*/


/* Contact Success and error Area css
============================================================================================ */

.modal-message .modal-dialog {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) !important;
    margin: 0px;
    max-width: 500px;
    width: 100%;
}

.modal-message .modal-dialog .modal-content .modal-header {
    text-align: center;
    display: block;
    border-bottom: none;
    padding-top: 50px;
    padding-bottom: 50px;
}

.modal-message .modal-dialog .modal-content .modal-header .close {
    position: absolute;
    right: -15px;
    top: -15px;
    padding: 0px;
    color: #fff;
    opacity: 1;
    cursor: pointer;
}

.modal-message .modal-dialog .modal-content .modal-header h2 {
    display: block;
    text-align: center;
    color: #f81c1c;
    padding-bottom: 10px;
    font-family: "Roboto", sans-serif;
}

.modal-message .modal-dialog .modal-content .modal-header p {
    display: block;
}


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/* Pricing table
======================================================================= */

.card-priceTable {
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    transition: all 300ms linear 0s;
}

.card-priceTable:hover .main_btn {
    background: #f81c1c;
    color: #fff;
    border-color: #f81c1c;
}

.priceTable-header {
    padding: 25px 9px;
    background: #f7f7f7;
    margin-bottom: 10px;
    transition: all 300ms linear 0s;
}

@media (min-width: 768px) {
    .priceTable-header {
        padding: 45px 10px 30px 10px;
    }
}

.priceTable-header h3 {
    font-size: 27px;
    color: #1a1d24;
}

.priceTable-header h1 {
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    color: #1a1d24;
}

.priceTable-header p {
    color: #777;
}

.priceTable-price {
    font-size: 22px;
}

@media (min-width: 768px) {
    .priceTable-price {
        font-size: 50px;
    }
}

.priceTable-price span {
    font-size: 22px;
    display: inline-block;
}

@media (min-width: 768px) {
    .priceTable-price span {
        font-size: 40px;
        position: relative;
        bottom: 7px;
        left: 11px;
    }
}

.priceTable-list {
    padding: 30px 10px 0;
    background: #f7f7f7;
    margin: 0;
    transition: all 300ms linear 0s;
}

.priceTable-list li {
    margin-bottom: 13px;
    transition: all 300ms linear 0s;
}

.priceTable-list li:last-child {
    margin-bottom: 0;
}

.priceTable-list li i,
.priceTable-list li span {
    font-size: 13px;
    margin-right: 3px;
}

.priceTable-list li i.positive,
.priceTable-list li span.positive {
    color: #797979;
}

.priceTable-footer {
    padding: 25px 9px;
    background: #f7f7f7;
}

@media (min-width: 768px) {
    .priceTable-footer {
        padding: 45px 0;
    }
}

.priceTable-footer .main_btn {
    padding: 0px 50px;
    font-weight: 500;
}


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/*============== Elements Area css ================*/

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.area-padding {
    padding-bottom: 100px;
}

.starter-padding {
    padding: 80px 0 50px 0;
}

.sample-text-area {
    padding: 100px 0px;
}

.sample-text-area .title_color {
    margin-bottom: 30px;
}

.sample-text-area p {
    line-height: 26px;
}

.sample-text-area p b {
    font-weight: bold;
    color: #f81c1c;
}

.sample-text-area p i {
    color: #f81c1c;
    font-style: italic;
}

.sample-text-area p sup {
    color: #f81c1c;
    font-style: italic;
}

.sample-text-area p sub {
    color: #f81c1c;
    font-style: italic;
}

.sample-text-area p del {
    color: #f81c1c;
}

.sample-text-area p u {
    color: #f81c1c;
}


/*============== End Elements Area css ================*/


/*==============Elements Button Area css ================*/

.elements_button .title_color {
    margin-bottom: 30px;
    color: #1a1d24;
}

.title_color {
    color: #1a1d24;
}

.button-group-area {
    margin-top: 15px;
}

.button-group-area:nth-child(odd) {
    margin-top: 40px;
}

.button-group-area:first-child {
    margin-top: 0px;
}

.button-group-area .theme_btn {
    margin-right: 10px;
}

.button-group-area .white_btn {
    margin-right: 10px;
}

.button-group-area .link {
    text-decoration: underline;
    color: #1a1d24;
    background: transparent;
}

.button-group-area .link:hover {
    color: #fff;
}

.button-group-area .disable {
    background: transparent;
    color: #007bff;
    cursor: not-allowed;
}

.button-group-area .disable:before {
    display: none;
}

.primary {
    background: #52c5fd;
}

.primary:before {
    background: #2faae6;
}

.success {
    background: #4cd3e3;
}

.success:before {
    background: #2ebccd;
}

.info {
    background: #38a4ff;
}

.info:before {
    background: #298cdf;
}

.warning {
    background: #f4e700;
}

.warning:before {
    background: #e1d608;
}

.danger {
    background: #f54940;
}

.danger:before {
    background: #e13b33;
}

.primary-border {
    background: transparent;
    border: 1px solid #52c5fd;
    color: #52c5fd;
}

.primary-border:before {
    background: #52c5fd;
}

.success-border {
    background: transparent;
    border: 1px solid #4cd3e3;
    color: #4cd3e3;
}

.success-border:before {
    background: #4cd3e3;
}

.info-border {
    background: transparent;
    border: 1px solid #38a4ff;
    color: #38a4ff;
}

.info-border:before {
    background: #38a4ff;
}

.warning-border {
    background: #fff;
    border: 1px solid #f4e700;
    color: #f4e700;
}

.warning-border:before {
    background: #f4e700;
}

.danger-border {
    background: transparent;
    border: 1px solid #f54940;
    color: #f54940;
}

.danger-border:before {
    background: #f54940;
}

.link-border {
    background: transparent;
    border: 1px solid #f81c1c;
    color: #f81c1c;
}

.link-border:before {
    background: #f81c1c;
}

.radius {
    border-radius: 3px;
}

.circle {
    border-radius: 20px;
}

.arrow span {
    padding-left: 5px;
}

.e-large {
    line-height: 50px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.large {
    line-height: 45px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.medium {
    line-height: 30px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.small {
    line-height: 25px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.general {
    line-height: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
}


/*==============End Elements Button Area css ================*/


/* =================================== */


/*  Elements Page Styles
/* =================================== */


/*---------- Start Elements Page -------------*/

.generic-banner {
    margin-top: 60px;
    text-align: center;
}

.generic-banner .height {
    height: 600px;
}

@media (max-width: 767.98px) {
    .generic-banner .height {
        height: 400px;
    }
}

.generic-banner .generic-banner-content h2 {
    line-height: 1.2em;
    margin-bottom: 20px;
}

@media (max-width: 991.98px) {
    .generic-banner .generic-banner-content h2 br {
        display: none;
    }
}

.generic-banner .generic-banner-content p {
    text-align: center;
    font-size: 16px;
}

@media (max-width: 991.98px) {
    .generic-banner .generic-banner-content p br {
        display: none;
    }
}

.generic-content h1 {
    font-weight: 600;
}

.about-generic-area {
    background: #fff;
}

.about-generic-area p {
    margin-bottom: 20px;
}

.white-bg {
    background: #fff;
}

.section-top-border {
    padding: 50px 0;
    border-top: 1px dotted #eee;
}

.switch-wrap {
    margin-bottom: 10px;
}

.switch-wrap p {
    margin: 0;
}


/*---------- End Elements Page -------------*/

.sample-text-area {
    padding: 100px 0 70px 0;
}

.sample-text {
    margin-bottom: 0;
}

.text-heading {
    margin-bottom: 30px;
    font-size: 24px;
}

.typo-list {
    margin-bottom: 10px;
}

@media (max-width: 767px) {
    .typo-sec {
        margin-bottom: 30px;
    }
}

@media (max-width: 767px) {
    .element-wrap {
        margin-top: 30px;
    }
}

b,
sup,
sub,
u,
del {
    color: #f8b600;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

.typography h1,
.typography h2,
.typography h3,
.typography h4,
.typography h5,
.typography h6 {
    color: #777777;
}

.button-area .border-top-generic {
    padding: 70px 15px;
    border-top: 1px dotted #eee;
}

.button-group-area .genric-btn {
    margin-right: 10px;
    margin-top: 10px;
}

.button-group-area .genric-btn:last-child {
    margin-right: 0;
}

.circle {
    border-radius: 20px;
}

.genric-btn {
    display: inline-block;
    outline: none;
    line-height: 40px;
    padding: 0 30px;
    font-size: .8em;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.genric-btn:focus {
    outline: none;
}

.genric-btn.e-large {
    padding: 0 40px;
    line-height: 50px;
}

.genric-btn.large {
    line-height: 45px;
}

.genric-btn.medium {
    line-height: 30px;
}

.genric-btn.small {
    line-height: 25px;
}

.genric-btn.radius {
    border-radius: 3px;
}

.genric-btn.circle {
    border-radius: 20px;
}

.genric-btn.arrow {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.genric-btn.arrow span {
    margin-left: 10px;
}

.genric-btn.default {
    color: #222222;
    background: #f9f9ff;
    border: 1px solid transparent;
}

.genric-btn.default:hover {
    border: 1px solid #f9f9ff;
    background: #fff;
}

.genric-btn.default-border {
    border: 1px solid #f9f9ff;
    background: #fff;
}

.genric-btn.default-border:hover {
    color: #222222;
    background: #f9f9ff;
    border: 1px solid transparent;
}

.genric-btn.primary {
    color: #fff;
    background: #f8b600;
    border: 1px solid transparent;
}

.genric-btn.primary:hover {
    color: #f8b600;
    border: 1px solid #f8b600;
    background: #fff;
}

.genric-btn.primary-border {
    color: #f8b600;
    border: 1px solid #f8b600;
    background: #fff;
}

.genric-btn.primary-border:hover {
    color: #fff;
    background: #f8b600;
    border: 1px solid transparent;
}

.genric-btn.success {
    color: #fff;
    background: #4cd3e3;
    border: 1px solid transparent;
}

.genric-btn.success:hover {
    color: #4cd3e3;
    border: 1px solid #4cd3e3;
    background: #fff;
}

.genric-btn.success-border {
    color: #4cd3e3;
    border: 1px solid #4cd3e3;
    background: #fff;
}

.genric-btn.success-border:hover {
    color: #fff;
    background: #4cd3e3;
    border: 1px solid transparent;
}

.genric-btn.info {
    color: #fff;
    background: #38a4ff;
    border: 1px solid transparent;
}

.genric-btn.info:hover {
    color: #38a4ff;
    border: 1px solid #38a4ff;
    background: #fff;
}

.genric-btn.info-border {
    color: #38a4ff;
    border: 1px solid #38a4ff;
    background: #fff;
}

.genric-btn.info-border:hover {
    color: #fff;
    background: #38a4ff;
    border: 1px solid transparent;
}

.genric-btn.warning {
    color: #fff;
    background: #f4e700;
    border: 1px solid transparent;
}

.genric-btn.warning:hover {
    color: #f4e700;
    border: 1px solid #f4e700;
    background: #fff;
}

.genric-btn.warning-border {
    color: #f4e700;
    border: 1px solid #f4e700;
    background: #fff;
}

.genric-btn.warning-border:hover {
    color: #fff;
    background: #f4e700;
    border: 1px solid transparent;
}

.genric-btn.danger {
    color: #fff;
    background: #f44a40;
    border: 1px solid transparent;
}

.genric-btn.danger:hover {
    color: #f44a40;
    border: 1px solid #f44a40;
    background: #fff;
}

.genric-btn.danger-border {
    color: #f44a40;
    border: 1px solid #f44a40;
    background: #fff;
}

.genric-btn.danger-border:hover {
    color: #fff;
    background: #f44a40;
    border: 1px solid transparent;
}

.genric-btn.link {
    color: #222222;
    background: #f9f9ff;
    text-decoration: underline;
    border: 1px solid transparent;
}

.genric-btn.link:hover {
    color: #222222;
    border: 1px solid #f9f9ff;
    background: #fff;
}

.genric-btn.link-border {
    color: #222222;
    border: 1px solid #f9f9ff;
    background: #fff;
    text-decoration: underline;
}

.genric-btn.link-border:hover {
    color: #222222;
    background: #f9f9ff;
    border: 1px solid transparent;
}

.genric-btn.disable {
    color: #222222, 0.3;
    background: #f9f9ff;
    border: 1px solid transparent;
    cursor: not-allowed;
}

.generic-blockquote {
    padding: 30px 50px 30px 30px;
    background: #fff;
    border-left: 2px solid #f8b600;
}

@media (max-width: 991px) {
    .progress-table-wrap {
        overflow-x: scroll;
    }
}

.progress-table {
    background: #fff;
    padding: 15px 0px 30px 0px;
    min-width: 800px;
}

.progress-table .serial {
    width: 11.83%;
    padding-left: 30px;
}

.progress-table .country {
    width: 28.07%;
}

.progress-table .visit {
    width: 19.74%;
}

.progress-table .percentage {
    width: 40.36%;
    padding-right: 50px;
}

.progress-table .table-head {
    display: flex;
}

.progress-table .table-head .serial,
.progress-table .table-head .country,
.progress-table .table-head .visit,
.progress-table .table-head .percentage {
    color: #222222;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: 500;
}

.progress-table .table-row {
    padding: 15px 0;
    border-top: 1px solid #edf3fd;
    display: flex;
}

.progress-table .table-row .serial,
.progress-table .table-row .country,
.progress-table .table-row .visit,
.progress-table .table-row .percentage {
    display: flex;
    align-items: center;
}

.progress-table .table-row .country img {
    margin-right: 15px;
}

.progress-table .table-row .percentage .progress {
    width: 80%;
    border-radius: 0px;
    background: transparent;
}

.progress-table .table-row .percentage .progress .progress-bar {
    height: 5px;
    line-height: 5px;
}

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
    background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
    background-color: #e66686;
}

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
    background-color: #f09359;
}

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
    background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
    background-color: #73fbaf;
}

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
    background-color: #6382e6;
}

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
    background-color: #a367e7;
}

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
    background-color: #e66686;
}

.single-gallery-image {
    margin-top: 30px;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    height: 200px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.single-gallery-image:hover {
    opacity: .8;
}

.list-style {
    width: 14px;
    height: 14px;
}

.unordered-list li {
    position: relative;
    padding-left: 30px;
    line-height: 1.82em !important;
}

.unordered-list li:before {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    border: 3px solid #f8b600;
    background: #fff;
    top: 4px;
    left: 0;
    border-radius: 50%;
}

.ordered-list {
    margin-left: 30px;
}

.ordered-list li {
    list-style-type: decimal-leading-zero;
    color: #f8b600;
    font-weight: 500;
    line-height: 1.82em !important;
}

.ordered-list li span {
    font-weight: 300;
    color: #777777;
}

.ordered-list-alpha li {
    margin-left: 30px;
    list-style-type: lower-alpha;
    color: #f8b600;
    font-weight: 500;
    line-height: 1.82em !important;
}

.ordered-list-alpha li span {
    font-weight: 300;
    color: #777777;
}

.ordered-list-roman li {
    margin-left: 30px;
    list-style-type: lower-roman;
    color: #f8b600;
    font-weight: 500;
    line-height: 1.82em !important;
}

.ordered-list-roman li span {
    font-weight: 300;
    color: #777777;
}

.single-input {
    display: block;
    width: 100%;
    line-height: 40px;
    border: none;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px;
}

.single-input:focus {
    outline: none;
}

.input-group-icon {
    position: relative;
}

.input-group-icon .icon {
    position: absolute;
    left: 20px;
    top: 0;
    line-height: 40px;
    z-index: 3;
}

.input-group-icon .icon i {
    color: #797979;
}

.input-group-icon .single-input {
    padding-left: 45px;
}

.single-textarea {
    display: block;
    width: 100%;
    line-height: 40px;
    border: none;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px;
    height: 100px;
    resize: none;
}

.single-textarea:focus {
    outline: none;
}

.single-input-primary {
    display: block;
    width: 100%;
    line-height: 40px;
    border: 1px solid transparent;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px;
}

.single-input-primary:focus {
    outline: none;
    border: 1px solid #f8b600;
}

.single-input-accent {
    display: block;
    width: 100%;
    line-height: 40px;
    border: 1px solid transparent;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px;
}

.single-input-accent:focus {
    outline: none;
    border: 1px solid #eb6b55;
}

.single-input-secondary {
    display: block;
    width: 100%;
    line-height: 40px;
    border: 1px solid transparent;
    outline: none;
    background: #f9f9ff;
    padding: 0 20px;
}

.single-input-secondary:focus {
    outline: none;
    border: 1px solid #f09359;
}

.default-switch {
    width: 35px;
    height: 17px;
    border-radius: 8.5px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.default-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.default-switch input+label {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #f8b600;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.default-switch input:checked+label {
    left: 19px;
}

.single-element-widget {
    margin-bottom: 30px;
}

.primary-switch {
    width: 35px;
    height: 17px;
    border-radius: 8.5px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.primary-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.primary-switch input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.primary-switch input+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 8.5px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
}

.primary-switch input+label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.primary-switch input:checked+label:after {
    left: 19px;
}

.primary-switch input:checked+label:before {
    background: #f8b600;
}

.confirm-switch {
    width: 35px;
    height: 17px;
    border-radius: 8.5px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.confirm-switch input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.confirm-switch input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.confirm-switch input+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 8.5px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    cursor: pointer;
}

.confirm-switch input+label:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.confirm-switch input:checked+label:after {
    left: 19px;
}

.confirm-switch input:checked+label:before {
    background: #4cd3e3;
}

.primary-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.primary-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.primary-checkbox input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
}

.single-defination h4 {
    color: #1a1d24;
}

.primary-checkbox input:checked+label {
    /* background: url(../img/elements/primary-check.png) no-repeat center center/cover; */
    border: none;
}

.confirm-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.confirm-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.confirm-checkbox input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
}

.confirm-checkbox input:checked+label {
    /* background: url(../img/elements/success-check.png) no-repeat center center/cover; */
    border: none;
}

.disabled-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.disabled-checkbox input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.disabled-checkbox input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
}

.disabled-checkbox input:disabled {
    cursor: not-allowed;
    z-index: 3;
}

.disabled-checkbox input:checked+label {
    /* background: url(../img/elements/disabled-check.png) no-repeat center center/cover; */
    border: none;
}

.primary-radio {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.primary-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.primary-radio input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
}

.primary-radio input:checked+label {
    /* background: url(../img/elements/primary-radio.png) no-repeat center center/cover; */
    border: none;
}

.confirm-radio {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.confirm-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.confirm-radio input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
}

.confirm-radio input:checked+label {
    /* background: url(../img/elements/success-radio.png) no-repeat center center/cover; */
    border: none;
}

.disabled-radio {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    cursor: pointer;
}

.disabled-radio input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.disabled-radio input+label {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
}

.disabled-radio input:disabled {
    cursor: not-allowed;
    z-index: 3;
}

.unordered-list {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.ordered-list {
    list-style: none;
    padding: 0px;
}

.disabled-radio input:checked+label {
    /* background: url(../img/elements/disabled-radio.png) no-repeat center center/cover; */
    border: none;
}

.default-select {
    height: 40px;
}

.default-select .nice-select {
    border: none;
    border-radius: 0px;
    height: 40px;
    background: #fff;
    padding-left: 20px;
    padding-right: 40px;
}

.default-select .nice-select .list {
    margin-top: 0;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    padding: 10px 0 10px 0px;
}

.default-select .nice-select .list .option {
    font-weight: 300;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 28px;
    min-height: 28px;
    font-size: 12px;
    padding-left: 20px;
}

.default-select .nice-select .list .option.selected {
    color: #f8b600;
    background: transparent;
}

.default-select .nice-select .list .option:hover {
    color: #f8b600;
    background: transparent;
}

.default-select .current {
    margin-right: 50px;
    font-weight: 300;
}

.default-select .nice-select::after {
    right: 20px;
}

@media (max-width: 991px) {
    .left-align-p p {
        margin-top: 20px;
    }
}

.form-select {
    height: 40px;
    width: 100%;
}

.form-select .nice-select {
    border: none;
    border-radius: 0px;
    height: 40px;
    background: #f9f9ff !important;
    padding-left: 45px;
    padding-right: 40px;
    width: 100%;
}

.form-select .nice-select .list {
    margin-top: 0;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    padding: 10px 0 10px 0px;
}

.mt-10 {
    margin-top: 10px;
}

.form-select .nice-select .list .option {
    font-weight: 300;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 28px;
    min-height: 28px;
    font-size: 12px;
    padding-left: 45px;
}

.form-select .nice-select .list .option.selected {
    color: #f8b600;
    background: transparent;
}

.form-select .nice-select .list .option:hover {
    color: #f8b600;
    background: transparent;
}

.form-select .current {
    margin-right: 50px;
    font-weight: 300;
}

.form-select .nice-select::after {
    right: 20px;
}


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/* Main Button Area css
============================================================================================ */

.main_btn {
    display: inline-block;
    background: yellow;
    padding: 0px 35px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 48px;
    border-radius: 3px;
    outline: none !important;
    box-shadow: none !important;
    text-align: center;
    border: 1px solid #1a1d24;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    transition: all 300ms linear 0s;
}

.main_btn:hover {
    background: red;
    color: #f81c1c;
    border: 1px solid #fff;
}

.main_btn2 {
    display: inline-block;
    background: #fff;
    padding: 0px 32px;
    color: #1a1d24;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
    border-radius: 5px;
    outline: none !important;
    box-shadow: none !important;
    text-align: center;
    border: 1px solid #fff;
    cursor: pointer;
    transition: all 300ms linear 0s;
}

.main_btn2:hover {
    background: #f81c1c;
    color: #fff;
    border-color: #f81c1c;
}

.submit_btn {
    width: auto;
    display: inline-block;
    background: #f81c1c;
    padding: 0px 50px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 50px;
    border-radius: 5px;
    outline: none !important;
    box-shadow: none !important;
    text-align: center;
    border: 1px solid #f81c1c;
    cursor: pointer;
    transition: all 300ms linear 0s;
}

.submit_btn:hover {
    background: transparent;
    color: #f81c1c;
}

.white_bg_btn {
    display: inline-block;
    background: #f9f9ff;
    padding: 0px 35px;
    color: #1a1d24;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 34px;
    border-radius: 0px;
    outline: none !important;
    box-shadow: none !important;
    text-align: center;
    border: 1px solid #eeeeee;
    cursor: pointer;
    transition: all 300ms linear 0s;
}

.white_bg_btn:hover {
    background: #f81c1c;
    color: #fff;
    border-color: #f81c1c;
}

.black_btn {
    padding: 0px 44px;
    line-height: 50px;
    background: #1a1d24;
    color: #f81c1c;
    display: inline-block;
    border-radius: 5px;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    transition: all 300ms linear 0s;
}

.black_btn:hover {
    background: #f81c1c;
    color: #1a1d24;
}


/*=================== custom button rule start ====================*/

.button {
    font-family: "Roboto", sans-serif;
    display: inline-block;
    border: 1px solid transparent;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 54px;
    border-radius: 4px;
    color: #fff;
    border: 1px solid #f81c1c;
    text-transform: uppercase;
    background: #f81c1c;
    cursor: pointer;
    transition: all 300ms linear 0s;
}

@media (max-width: 767px) {
    .button {
        font-size: 13px;
        padding: 9px 24px;
    }
}

.button:hover {
    background: #f81c1c;
    color: #fff;
}

.button-link {
    letter-spacing: 0;
    color: #3b1d82;
    border: 0;
    padding: 0;
}

.button-link:hover {
    background: transparent;
    color: #3b1d82;
}

.button-header {
    background: #f81c1c;
    color: #fff;
    border-color: #f81c1c;
}

.button-header:hover {
    background: #b8024c;
    color: #fff;
}

.button-contactForm {
    background: #f81c1c;
    color: #fff;
    border-color: #f81c1c;
    padding: 12px 25px;
}

.button-contactForm:hover {
    border-color: #2a2a2a;
    background: #2a2a2a;
    color: #fff;
}


/* End Main Button Area css
============================================================================================ */


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/* Testimonial section start
============================================================================================ */

.testimonial {
    max-width: 950px;
    margin-right: auto;
    margin-left: auto;
}

.testimonial .owl-dot {
    width: 10px;
    height: 10px;
    background: transparent;
    display: inline-block;
    margin: 0 8px;
    border-radius: 7px;
    border: 1px solid #797979;
}

.testimonial .owl-dot.active {
    background: #f81c1c;
    border: 1px solid #f81c1c;
}

.testimonial .owl-dots {
    position: absolute;
    bottom: -16px;
    left: 65%;
}

.testimonial__item .testimonial__img {
    padding-left: 35px;
}

@media (max-width: 767px) {
    .testimonial__item .testimonial__img {
        padding-left: 0;
        width: 100px;
        margin-bottom: 17px;
        border-radius: 63px;
        overflow: hidden;
    }
}

.testimonial_para_wrapper {
    background: #fff;
    padding: 56px 50px 75px;
    margin-left: -60px;
    margin-top: 8px;
}

@media (max-width: 991px) {
    .testimonial_para_wrapper {
        padding: 18px 30px;
        margin-left: -52px;
    }
}

.testimonial__content h3 {
    font-size: 20px;
    margin-bottom: 0;
    display: inline;
    float: left;
}

.testimonial__content h6 {
    font-size: 14px;
    margin-bottom: 0;
    display: inline;
    color: #797979;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.testimonial__content span {
    padding: 0 8px;
    margin-top: 3px;
    display: inline-block;
}

.testimonial__i {
    font-size: 15px !important;
    font-style: italic;
    margin-bottom: 0 !important;
}

.testimonial__icon {
    font-size: 173px;
    color: rgba(119, 119, 119, 0.1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    right: 50%;
}

.testimonial .owl-dots {
    margin-top: 30px !important;
}

@media (min-width: 600px) {
    .testimonial .owl-dots {
        margin-top: 60px !important;
    }
}

@media (max-width: 767px) {
    .testimonial .owl-dots {
        bottom: -40px;
        left: 33%;
    }
    .testimonial_para_wrapper {
        margin-left: 0;
    }
    .testimonial__icon {
        font-size: 122px;
        top: 59%;
        right: 2%;
    }
}

.testimonial .owl-dots .owl-dot span {
    width: 20px;
    height: 4px;
    background: #cdcad5;
    border-radius: 5px;
    margin: 5px 8px;
}

.testimonial .owl-dots .owl-dot.active span {
    height: 6px;
    background: #6059f6;
}


/* Testimonial section end
============================================================================================ */


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/* Team Area css
============================================================================================ */

.team_area {
    background: #f9f9ff;
}

.team_inner {
    margin-bottom: -30px;
}

.team_item {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
}

.team_item .team_img {
    position: relative;
}

.team_item .team_img img {
    width: 100%;
}

.team_item .team_img:before {
    content: "";
    background: rgba(255, 255, 255, 0.65);
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 300ms ease;
    border-radius: 50%;
}

.team_item .team_img .hover {
    position: absolute;
    left: 0px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    opacity: 0;
    transition: all 300ms linear 0s;
}

.team_item .team_img .hover a {
    font-size: 14px;
    color: #797979;
    margin-right: 20px;
    transition: all 300ms linear 0s;
}

.team_item .team_img .hover a:last-child {
    margin-right: 0px;
}

.team_item .team_img .hover a:hover {
    color: #f81c1c;
}

.team_item .team_name {
    text-align: center;
    margin-top: 30px;
}

.team_item .team_name h4 {
    margin-bottom: 5px;
    color: #1a1d24;
    font-family: "Playfair Display", serif;
    font-weight: bold;
    font-size: 18px;
    transition: all 300ms linear 0s;
}

.team_item .team_name p {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #797979;
    margin-bottom: 0px;
}

.team_item:hover .team_img:before {
    opacity: 1;
}

.team_item:hover .team_img .hover {
    opacity: 1;
}

.team_item:hover .team_name h4 {
    color: #f81c1c;
}


/* End Team Area css
============================================================================================ */


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/* Courses Area css
============================================================================================ */


/* Start Portfolio Area css
============================================================================================ */

@media (max-width: 991px) {
    .portfolio_area {
        padding-bottom: 50px;
    }
}

.portfolio_area .filters ul {
    padding-left: 0;
    margin-bottom: 30px;
}

@media (max-width: 991px) {
    .portfolio_area .filters ul {
        margin-bottom: 20px;
    }
}

.portfolio_area .filters ul li {
    display: inline-block;
    margin-right: 40px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #1a1d24;
    cursor: pointer;
    position: relative;
    transition: all 300ms linear 0s;
}

.portfolio_area .filters ul li:hover {
    color: grey;
}

@media (max-width: 767px) {
    .portfolio_area .filters ul li {
        margin-right: 20px;
    }
}

.portfolio_area .filters ul li:before {
    content: '';
    position: absolute;
    right: 0px;
    top: 21px;
    left: 100%;
    height: 2px;
    background: black;
}

.portfolio_area .filters ul li:hover:before {
    left: 0px;
    transition: all 300ms linear 0s;
}

@media (max-width: 600px) {
    .portfolio_area .filters ul li:hover:before {
        left: -8px;
    }
}

@media (max-width: 600px) {
    .portfolio_area .filters ul li {
        margin-right: 15px;
        margin-bottom: 10px;
    }
}

.portfolio_area .filters ul li.active {
    color: grey;
}

.portfolio_area .filters ul li.active:before {
    left: 0px;
    transition: all 300ms linear 0s;
}

.single_portfolio {
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
}

.single_portfolio:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    z-index: 0;
}

@media (max-width: 767px) {
    .single_portfolio {
        margin-bottom: 35px;
    }
}

.single_portfolio .short_info {
    position: absolute;
    bottom: 20px;
    left: 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms linear 0s;
    padding: 20px 37px;
    transition: all 300ms linear 0s;
}

.single_portfolio .short_info h4 {
    text-transform: capitalize;
    font-size: 20px;
    margin-bottom: 5px;
}

.single_portfolio .short_info h4 a {
    color: #1a1d24;
}

.single_portfolio .short_info p {
    margin-bottom: 0px;
    text-transform: uppercase;
    font-weight: 500;
}

.single_portfolio:hover .short_info {
    opacity: 1;
    visibility: visible;
}

.single_portfolio:hover:before {
    opacity: .1;
    transition: all 300ms linear 0s;
}


/* End Portfolio Area css
============================================================================================ */


/* Start Portfolio Details Area css 
============================================================================================ */

.portfolio_details_area {
    padding-top: 130px;
}

@media (max-width: 1199px) {
    .portfolio_details_area {
        padding-top: 80px;
    }
}

.recent-portfolio {
    padding: 70px 0 130px;
}

.recent-portfolio .area-heading {
    margin-bottom: 41px;
    text-align: left;
}

@media (max-width: 1199px) {
    .recent-portfolio {
        padding: 70px 0 80px;
    }
}

.portfolio_details_inner {
    padding-bottom: 70px;
    border-bottom: 1px solid #eeeeee;
}

@media (max-width: 480px) {
    .portfolio_details_inner .row {
        margin-bottom: 30px;
    }
}

.portfolio_details_inner p {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 26px;
}

.portfolio_details_inner p:last-child {
    margin-bottom: 0px;
}

.portfolio_details_inner .portfolio_right_text {
    padding: 30px;
    background: #f6f6f6;
}

@media (max-width: 991px) {
    .portfolio_details_inner .portfolio_right_text {
        margin-top: 0px;
    }
}

@media (max-width: 576px) {
    .portfolio_details_inner .portfolio_right_text {
        margin-top: 50px;
    }
}

.portfolio_details_inner .portfolio_right_text h4 {
    font-size: 21px;
    text-transform: capitalize;
    margin-bottom: 38px;
    font-weight: 700;
}

.portfolio_details_inner .portfolio_right_text .list li {
    width: 100%;
    text-align: left;
    font-weight: 700;
    font-size: 16px;
    color: #000;
    font-family: "Playfair Display", serif;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 16px;
    padding-bottom: 15px;
}

.portfolio_details_inner .portfolio_right_text .list li span {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    float: right;
    font-family: "Roboto", sans-serif;
    color: #777777;
}

.portfolio_details_inner .portfolio_right_text .list li:last-child {
    margin-bottom: 0px;
}

.portfolio_details_inner .portfolio_right_text .list li i {
    color: #f81c1c;
    font-size: 13px;
    margin-left: 2px;
}

.portfolio_details_inner .portfolio_right_text .list li:last-child {
    border-bottom: none;
}

.portfolio_details_inner .project_image {
    margin-bottom: 50px;
}


/* End Portfolio Details Area css
============================================================================================ */


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/* Service  Area css
============================================================================================ */

.service-area .single-service {
    border: 1px solid transparent;
    background: #f7f7f7;
    text-align: center;
    padding: 55px 20px 30px;
    transition: all 300ms linear 0s;
}

.service-area .single-service:hover {
    box-shadow: 0px 10px 36px 4px rgba(131, 131, 131, 0.12);
    background: #fff;
    transition: all 300ms linear 0s;
}

.service-area .single-service:hover .service-icon {
    color: #f81c1c;
    transition: all 300ms linear 0s;
}

.service-area .single-service .service-icon {
    margin-bottom: 20px;
    color: #1a1d24;
}

.service-area .single-service .service-icon .flaticon-balloon {
    transform: rotate(41deg);
    display: inline-block;
}

.service-area .single-service .service-icon .flaticon-balloon {
    transform: rotate(41deg);
    display: inline-block;
}

.service-area .single-service .service-icon .flaticon-camera {
    display: inline-block;
    transform: rotate(31deg);
}

.service-area .single-service .service-content h5 {
    font-size: 20px;
    color: #1a1d24;
    margin-bottom: 18px;
}

.service-area .single-service .service-content p {
    line-height: 27px;
}

.service-page.service-area {
    background: #fff;
}

.service-page.service-area .single-service {
    background: #f7f7f7;
}

.area-heading {
    margin-bottom: 85px;
    text-align: center;
}

.area-heading h3 {
    font-size: 36px;
    color: #1a1d24;
    position: relative;
    text-transform: capitalize;
}

.area-heading h3 span {
    color: #f81c1c;
    font-style: italic;
}

.area-heading p {
    font-size: 14px;
    color: #797979;
}


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/* About Area css
============================================================================================ */

@media (min-width: 1199px) and (max-width: 1199px) {
    .about-area .about-content {
        padding-top: 30px;
    }
}

@media (max-width: 991px) {
    .about-area .about-content {
        margin-top: 50px;
    }
    .about-area {
        padding-left: 20px;
        padding-right: 20px;
    }

}

@media (max-width: 767px) {
    .about-area .about-content {
        margin-top: 0;
    }
    .about-area {
        padding-left: 20px;
        padding-right: 20px;
    }
}


.about-area .about-content h4 {
    font-size: 36px;
    color: #1a1d24;
    font-family: "Playfair Display", serif;
    margin-bottom: 18px;
    line-height: 48px;
    
}

.about-area .about-content p {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    color: #777;
    line-height: 28px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .about-area .about-content p {
        font-size: 13px;
        margin-bottom: 10px;
    }
}

.about-area .about-content .main_btn {
    
    margin-top: 7px;
    background: #f81c1c;
    border-color: #f81c1c;
}

.about-area .about-content .main_btn:hover {
    background: #1a1d24;
    color: #fff;
    border-color: #1a1d24;
}

.img-styleBox {
    padding-left: 55px;
}

@media (max-width: 767px) {
    .img-styleBox {
        display: none;
    }
}

.img-styleBox .styleBox-border {
    position: relative;
    margin-bottom: 100px;
}

.img-styleBox .styleBox-border:after {
    content: '';
    position: absolute;
    width: 70%;
    height: 100%;
    /* background-image: url(../img/pattern1.jpg); */
    left: -55px;
    bottom: -55px;
    z-index: -1;
}

.img-styleBox .styleBox-img2 {
    position: absolute;
    bottom: 0;
    right: 20px;
}


/*---------------------------------------------------- */


/*----------------------------------------------------*/


/*============== footer-area css ===============*/

.footer-area {
    padding: 125px 0;
    background-color: #808080;
}

.footer-area h6 {
    color: #fff;
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 700;
}

@media (max-width: 767px) {
    .footer-area h6 {
        margin-bottom: 15px;
    }
}

.copy-right-text i,
.copy-right-text a {
    color: #f81c1c;
}

.instafeed {
    margin: -5px;
}

.instafeed li {
    overflow: hidden;
    width: 25%;
}

.instafeed li img {
    margin: 5px;
}

.footer-social a {
    padding-right: 25px;
    transition: all 300ms linear 0s;
}

.footer-social a:hover i {
    color: #f81c1c;
}

.footer-social i {
    color: #cccccc;
    transition: all 300ms linear 0s;
}

@media (max-width: 991px) {
    .single-footer-widget {
        margin-bottom: 40px;
    }
}

.single-footer-widget .form-inline {
    display: inline-block;
    width: 100%;
}

.single-footer-widget input {
    border: 1px solid #555;
    border-right: 1px solid #555;
    width: 80% !important;
    font-weight: 300;
    background: transparent;
    color: #555555;
    padding-left: 20px;
    border-radius: 0;
    font-size: 14px;
}

.single-footer-widget input:focus {
    outline: none;
    box-shadow: none;
    border-color: white;
    border-right: 1px solid white;
    background: transparent;
    color: #fff;
}

.single-footer-widget input.placeholder {
    color: #555;
}

.single-footer-widget input:-moz-placeholder {
    color: #555;
}

.single-footer-widget input::-moz-placeholder {
    color: #555;
}

.single-footer-widget input::-webkit-input-placeholder {
    color: #555;
}

.single-footer-widget .bb-btn {
    color: #fff;
    font-weight: 300;
    border-radius: 0;
    z-index: 9999;
    cursor: pointer;
}

.single-footer-widget .info {
    position: absolute;
    margin-top: 5%;
    color: #fff;
    font-size: 12px;
}

.single-footer-widget .info.valid {
    color: green;
}

.single-footer-widget .info.error {
    color: red;
}

.single-footer-widget .click-btn {
    background: #00B193;
    color: #fff;
    border-radius: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 6px 12px;
    border: 0;
}

.single-footer-widget .click-btn:focus {
    outline: none;
    box-shadow: none;
}

.single-footer-widget ::-moz-selection {
    /* Code for Firefox */
    background-color: #191919 !important;
    color: #797979;
}

.single-footer-widget ::selection {
    background-color: #191919 !important;
    color: #797979;
}

.single-footer-widget ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #797979;
    font-weight: 300;
}

.single-footer-widget :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #797979;
    opacity: 1;
    font-weight: 300;
}

.single-footer-widget ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #797979;
    opacity: 1;
    font-weight: 300;
}

.single-footer-widget :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #797979;
    font-weight: 300;
}

.single-footer-widget ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #797979;
    font-weight: 300;
}

@media (max-width: 991px) {
    .footer-text {
        padding-top: 40px;
    }
}

.footer-text a,
.footer-text i {
    color: #f81c1c;
}


/*---------------------------------------------------- */


/* sourceMappingURL=style.css.map */