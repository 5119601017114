@media (max-width:1619px){
	/* Main Menu Area css
	============================================================================================ */
	.header_area .navbar .search {
		margin-left: 40px;
	}
	/* End Main Menu Area css
	============================================================================================ */
	.testimonials_area .owl-prev, .testimonials_area .owl-next {
		right: 15px;
	}
}
@media (max-width:1300px){
	.testimonials_area .owl-prev, .testimonials_area .owl-next {
		right: auto;
		left: 50%;
		transform: translateX(-50%);
		bottom: 25px;
		top: auto;
	}
	.testimonials_area .owl-prev{
		transform: rotate(90deg);
	}
	.testimonials_area .owl-next{
		transform: rotate(90deg);
		margin-left: -40px;
	}
}
@media (max-width:1199px){

	/* Main Menu Area css
	============================================================================================ */
	.header_area .navbar .nav .nav-item {
		margin-right: 40px;
	}
	.header_area .navbar .nav > .nav-item:hover::before {
		left: -13px;
	}
	
	/* End Main Menu Area css
	============================================================================================ */
	/* Home Banner Area css
	============================================================================================ */
	.home_banner_area {
		min-height: 700px;
	}
	.home_banner_area .banner_inner {
		min-height: 700px;
	}
	/* End Home Banner Area css
	============================================================================================ */

	/* Element Area css
	============================================================================================ */

	.area-padding {
		padding: 80px 0;
	}

	.area-heading {
		margin-bottom: 50px;
	}

	/* End Element Area css
	============================================================================================ */

	/* Service Area css
	============================================================================================ */

	.service-area .single-service {
		margin-bottom: 30px;
	}

	/* End service Area css
	============================================================================================ */
	
	.testimonial-area .owl-dots {
		bottom: -30px;
	}

}

@media (max-width:991px){
	/* Main Menu Area css
	============================================================================================ */
	/* .navbar-toggler {
		border: none;
		border-radius: 0px;
		padding: 0px;
		cursor: pointer;
		margin-top: 27px;
		margin-bottom: 23px;
	}
	.header_area .navbar {
		background: #fff;
	}
	.navbar-toggler[aria-expanded="false"] span:nth-child(2) {
		opacity: 1;
	}
	.navbar-toggler[aria-expanded="true"] span:nth-child(2) {
		opacity: 0;
	}
	.navbar-toggler[aria-expanded="true"] span:first-child {
		transform: rotate(-45deg);
		position: relative;
		top: 7.5px;
	}
	.navbar-toggler[aria-expanded="true"] span:last-child {
		transform: rotate(45deg);
		bottom: 6px;
		position: relative;
	}
	.navbar-toggler span{
		display: block;
		width: 25px;
		height: 3px;
		background: #e22104;
		margin: auto;
		margin-bottom: 4px;
		transition: all 400ms linear;
		cursor: pointer;
	} */
	.navbar .container{
		padding-left: 15px;
		padding-right: 15px;
	}
	.nav{
		padding: 0px 0px;
	}
	.header_area + section, .header_area + row, .header_area + div {
		margin-top: 70px;
	}
	.header_top .nav{
		padding: 0px;
	}
	.header_area .navbar .nav .nav-item .nav-link{
		line-height: 40px;
		margin-right: 0px;
		display: block;
		border-bottom: 1px solid #ededed33;
		border-radius: 0px;
	}
	.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
		line-height: 40px;
	}
	.header_area .navbar .search{
		margin-left: 0px;
	}
	.header_area .navbar-collapse{
		max-height: 340px;
		overflow-y: scroll;
	}
	.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
		padding: 0px 15px;
	}
	.header_area .navbar .nav .nav-item {
		margin-right: 0px;
	}
	.header_area .navbar .nav > .nav-item::after {
		display: none;
	}
	
	.header_area .navbar .nav > .nav-item::before {
		display: none;
	}
	.header_area .right-button {
		margin-left: 5px;
		margin-top: 20px;
	}
	/* End Main Menu Area css
	============================================================================================ */
	/* Blog page Area css
	============================================================================================ */
	.categories_post img{
		width: 100%;
	}
	.categories_post {
		max-width: 360px;
		margin: 0 auto;
	}
	.blog_categorie_area .col-lg-4{
		margin-top: 30px;
	}
	.blog_area{
		padding-bottom: 80px;
	}
	.single-post-area .blog_right_sidebar{
		margin-top: 30px;
	}
	/* End Blog page Area css
	============================================================================================ */
	
	/* Contact Page Area css
	============================================================================================ */
	.contact_info{
		margin-bottom: 50px;
	}
	/* End Contact page Area css
	============================================================================================ */
	.home_banner_area .donation_inner{
		margin-bottom: -30px;
	}
	.home_banner_area .dontation_item{
		max-width: 350px;
		margin: auto;
	}
	/* Footer Area css
	============================================================================================ */
	.footer-area .col-sm-6{
		margin-bottom: 30px;
	}
	.footer-area {
		padding: 125px 0 85px;
	}
	/* End End Footer Area css
	============================================================================================ */
	.finance_item{
		margin-bottom: 30px;
	}
	.finance_inner{
		margin-bottom: -30px;
	}
	.team_item{
		max-width: 263px;
		margin: 0px auto 30px;
	}
	.packages_text{
		margin-bottom: 50px;
	}
	.packages_item{
		max-width: 360px;
		margin: 0px auto 30px;
	}
	.packages_inner{
		margin-bottom: -30px;
	}
	.l_blog_item img{
		width: 100%;
	}
	.news_widget {
		padding-left: 0px;
	}
	.video_area{
		margin-top: 40px;
		float: none;
		display: block;
	}
	.video_area > img{
		width: 100%;
	}
	.c_details_list{
		margin-top: 30px;
	}

	.number-content {
		padding-top: 0;
	}

	.testimonial-area .owl-dots {
		bottom: -28px;
	}
	.single-blog .meta-top a {
		margin-right: 5px;
	}
	.single-blog .meta-top a::after {
		display: none;
	}
}


@media (max-width:767px){

	.header_area + section, .header_area + row, .header_area + div {
		margin-top: 80px;
	}


	.home_banner_area {
		min-height: 500px;
	}
	.home_banner_area .banner_inner {
		min-height: 500px;
	}
	.home_banner_area .banner_inner .banner_content {
		margin-top: 0px;
	}
	
	/* Blog Page Area css
	============================================================================================ */
	.blog_banner .banner_inner .blog_b_text h2 {
		font-size: 40px;
		line-height: 50px;
	}
	.blog_info.text-right{
		text-align: left !important;
		margin-bottom: 10px;
	}
	/* End Blog Page Area css
	============================================================================================ */
	.home_banner_area .banner_inner .banner_content h3 {
		font-size: 30px;
	}
	.home_banner_area .banner_inner .banner_content p br {
		display: none;
	}
	.home_banner_area .banner_inner .banner_content h3 span {
		line-height: 45px;
		padding-bottom: 0px;
		padding-top: 0px;
	}
	/* Footer Area css
	============================================================================================ */
	.footer-bottom{
		text-align: center;
	}
	.footer-bottom .footer-social {
		text-align: center;
		margin-top: 15px;
	}
	.single-footer-widget .form-group .main_btn {
		margin-left: 25px;
	}
	.footer-area {
		padding: 70px 0px 20px;
	}
	/* End End Footer Area css
	============================================================================================ */
	.wd55 {
		width: 100%;
	}
	.courses_inner .courses_item img{
		width: 100%;
	}
	.wd44 {
		width: 100%;
	}
	.course_item img{
		width: 100%;
	}
	.number-content {
		padding-top: 50px;
	}
	.single-blog {
		margin-bottom: 50px;
	}
	.single-footer-widget {
		margin-bottom: 30px;
	}
	.about-area .about-content {
		padding-left: 0;
	}
}
@media (max-width:600px){
	.builder_inner .builder_item {
		flex: 0 0 100%;
		max-width: 100%;
	}
}
@media (max-width:575px){
	.top_menu {
		display: none;
	}
	.header_area + section, .header_area + row, .header_area + div {
		margin-top: 71px;
	}
    /* Home Banner Area css
    ============================================================================================ */
    .home_banner_area .banner_inner .banner_content h2 {
    	font-size: 28px;
    }
    .home_banner_area {
    	min-height: 500px;
    }
    .home_banner_area .banner_inner {
    	min-height: 500px;
    }
    .blog_banner .banner_inner .blog_b_text {
    	margin-top: 0px;
    }
    .home_banner_area .banner_inner .banner_content img{
    	display: none;
    }
    .home_banner_area .banner_inner .banner_content h5 {
    	margin-top: 0px;
    }
	/* End Home Banner Area css
	============================================================================================ */
	.p_120 {
		padding-top: 70px;
		padding-bottom: 70px;
	}
	.main_title h2 {
		font-size: 25px;
	}
	/* Elements Area css
	============================================================================================ */
	.sample-text-area {
		padding: 70px 0 70px 0;
	}
	.generic-blockquote {
		padding: 30px 15px 30px 30px;
	}
	/* End Elements Area css
	============================================================================================ */
	
	/* Blog Page Area css
	============================================================================================ */
	.blog_details h2 {
		font-size: 20px;
		line-height: 30px;
	}
	.blog_banner .banner_inner .blog_b_text h2 {
		font-size: 28px;
		line-height: 38px;
	}
	/* End Blog Page Area css
	============================================================================================ */
	/* Footer Area css
	============================================================================================ */
	.footer-area {
		padding: 70px 0px 20px;
	}
	/* End End Footer Area css
	============================================================================================ */
	.pad_top {
		padding-top: 70px;
	}

	.area-heading h3 {
		font-size: 30px;
	}
}

@media (max-width:480px){
	/* Main Menu Area css
	============================================================================================ */
	.header_area .navbar-collapse{
		max-height: 250px;
	}
	/* End Main Menu Area css
	============================================================================================ */
	
	/* Home Banner Area css
	============================================================================================ */
	.home_banner_area .banner_inner .banner_content {
		padding: 30px 15px;
		margin-top: 0px;
	}
	.banner_content .white_btn {
		display: block;
	}
	.home_banner_area .banner_inner .banner_content h3 {
		font-size: 24px;
	}
	/* End Home Banner Area css
	============================================================================================ */
	.banner_area .banner_inner .banner_content h2 {
		font-size: 32px;
	}
	.home_banner_area {
		min-height: 400px;
	}
	.home_banner_area .banner_inner {
		min-height: 400px;
	}
	/* Blog Page Area css
	============================================================================================ */
	.comments-area .thumb {
		margin-right: 10px;
	}
	
	/* End Blog Page Area css
	============================================================================================ */
	.video_area .popup-youtube img {
		width: 15%;
	}
	.course_details_inner .nav.nav-tabs li {
		margin-right: 15px;
	}
}