.blog_item_date p {
  color: white;
}

.crop {
  width: 200px;
  height: 150px;
  overflow: hidden;
}

.crop img {
  width: 400px;
  height: 300px;
  margin: -75px 0 0 -100px;
}

.blog_item_date {
  font-family: "open sans", sans-serif;
}

.blog_item_date h3 {
  font-family: "open sans", sans-serif;
}

.rounded-0 {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.blog_item {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.single_portfolio {
  box-shadow: 0 10px 20px 0 hsla(0, 0%, 90%, 0.5) !important;
}

.blog_item_date {
  overflow-x: show !important;
}

.blog_details {
  padding: 45px 25px 45px 20px !important;
  box-shadow: 0px 10px 20px 0px rgba(221, 221, 221, 0.5);
}

.ant-card-meta-title {
  white-space: pre-line !important;
}
