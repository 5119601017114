@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@800&family=Nunito&family=Open+Sans&display=swap");

.svg {
  display: block;
  width: 560px;
  margin-left: 20px;
  height: 120px;
  margin: 0 auto;
  overflow-x: visible!important;
}
path {
  overflow-x: visible !important;
  padding-left: 20px!important;
}

path {
  overflow-x: visible !important;
}

.star {
  color: white;
  padding: 10px;
}

.circle {
  background-color: #ff8c19;
  padding: 7px;
  border-radius: 50%;
}

.text-copy {
  fill: none;
  stroke: white;
  stroke-dasharray: 6% 29%;
  stroke-width: 5px;
  stroke-dashoffset: 0%;

  transition: ease-in all 2s;
  -webkit-transition: ease-in all 2s;
  -moz-transition: ease-in all 2s;
  -ms-transition: ease-in all 2s;
  -o-transition: ease-in all 2s;

  animation: stroke-offset 5.5s infinite reverse;
  -webkit-animation: stroke-offset 5.5s infinite linear;
  -moz-animation: stroke-offset 5.5s infinite linear;
  -ms-animation: stroke-offset 5.5s infinite linear;
  -o-animation: stroke-offset 5.5s infinite linear;
}

.text-copy:nth-child(1) {
  stroke: #f08811;
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  -moz-animation-delay: -1s;
  -ms-animation-delay: -1s;
  -o-animation-delay: -1s;
}

.text-copy:nth-child(2) {
  stroke: #1f3326;
  animation-delay: -2s;
  -webkit-animation-delay: -2s;
  -moz-animation-delay: -2s;
  -ms-animation-delay: -2s;
  -o-animation-delay: -2s;
}

.text-copy:nth-child(3) {
  stroke: #808080;
  animation-delay: -3s;
  -webkit-animation-delay: -3s;
  -moz-animation-delay: -3s;
  -ms-animation-delay: -3s;
  -o-animation-delay: -3s;
}

.text-copy:nth-child(4) {
  stroke: #f8fafc;
  animation-delay: -4s;
  -webkit-animation-delay: -4s;
  -moz-animation-delay: -4s;
  -ms-animation-delay: -4s;
  -o-animation-delay: -4s;
}

.text-copy:nth-child(5) {
  stroke: #30957b;
  animation-delay: -5s;
  -webkit-animation-delay: -5s;
  -moz-animation-delay: -5s;
  -ms-animation-delay: -5s;
  -o-animation-delay: -5s;
}

@keyframes stroke-offset {
  from {
    stroke-dashoffset: -35%;
  }
}

@-webkit-keyframes stroke-offset {
  from {
    stroke-dashoffset: 500;
  }
}

.home_banner_area {
  background: rgb(179, 179, 179);

  width: 100%;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgb(245, 175, 105);
  animation: animate 10s linear infinite;
  bottom: -30vh;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  background: rgb(55, 177, 147);
  animation-delay: 4s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 120px;
  height: 120px;
  animation-delay: 6s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 140px;
  height: 140px;
  background: rgb(55, 177, 147);
  animation-delay: 8s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 4s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 120px;
  height: 120px;
  animation-delay: 4s;
  background: rgb(55, 177, 147);
  animation-duration: 13s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 17s;
  animation-duration: 16s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 14s;
  background: rgb(55, 177, 147);
  animation-duration: 21s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 125px;
  height: 125px;
  animation-delay: 19s;
  animation-duration: 25s;
}

.circles li:nth-child(9) {
  left: 50%;
  width: 115px;
  height: 115px;
  background: rgb(55, 177, 147);
  animation-delay: 6s;
  animation-duration: 19s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 4s;
  animation-duration: 15s;
}
.circles li:nth-child(11) {
  left: 76%;
  width: 71px;
  height: 71px;
  background: rgb(55, 177, 147);
  animation-delay: 10s;
  animation-duration: 17s;
}
.circles li:nth-child(12) {
  left: 10%;
  width: 116px;
  height: 116px;
  animation-delay: 7s;
  animation-duration: 25s;
}
.circles li:nth-child(13) {
  left: 59%;
  width: 81px;
  height: 81px;
  background: rgb(55, 177, 147);
  animation-delay: 8s;
  animation-duration: 12s;
}
.circles li:nth-child(14) {
  left: 58%;
  width: 64px;
  height: 64px;
  animation-delay: 9s;
  animation-duration: 24s;
}
.circles li:nth-child(15) {
  left: 85%;
  width: 52px;
  height: 52px;
  background: rgb(55, 177, 147);
  animation-delay: 11s;
  animation-duration: 14s;
}
.circles li:nth-child(16) {
  left: 14%;
  width: 141px;
  height: 141px;
  animation-delay: 6s;
  animation-duration: 22s;
}
.circles li:nth-child(17) {
  left: 24%;
  width: 150px;
  height: 150px;
  background: rgb(55, 177, 147);
  animation-delay: 5s;
  animation-duration: 25s;
}
.circles li:nth-child(18) {
  left: 37%;
  width: 68px;
  height: 68px;
  animation-delay: 8s;
  animation-duration: 12s;
}
.circles li:nth-child(19) {
  left: 89%;
  width: 180px;
  height: 180px;
  background: rgb(55, 177, 147);
  animation-delay: 10s;
  animation-duration: 18s;
}
.circles li:nth-child(20) {
  left: 40%;
  width: 125px;
  height: 125px;
  animation-delay: 7s;
  animation-duration: 20s;
}
.circles li:nth-child(21) {
  left: 55%;
  width: 109px;
  height: 109px;
  background: rgb(55, 177, 147);
  animation-delay: 14s;
  animation-duration: 10s;
}
.circles li:nth-child(22) {
  left: 67%;
  width: 129px;
  height: 129px;
  animation-delay: 14s;
  animation-duration: 18s;
}

.circles li:nth-child(23) {
  left: 2%;
  width: 52px;
  height: 52px;
  background: rgb(55, 177, 147);
  animation-delay: 17s;
  animation-duration: 14s;
}
.circles li:nth-child(24) {
  left: 67%;
  width: 141px;
  height: 141px;
  animation-delay: 25s;
  animation-duration: 12s;
}
.circles li:nth-child(25) {
  left: 29%;
  width: 150px;
  height: 150px;
  background: rgb(55, 177, 147);
  animation-delay: 15s;
  animation-duration: 11s;
}
.circles li:nth-child(26) {
  left: 43%;
  width: 68px;
  height: 68px;
  animation-delay: 8s;
  animation-duration: 15s;
}
.circles li:nth-child(27) {
  left: 29%;
  width: 180px;
  height: 180px;
  background: rgb(55, 177, 147);
  animation-delay: 20s;
  animation-duration: 12s;
}
.circles li:nth-child(28) {
  left: 49%;
  width: 125px;
  height: 125px;
  animation-delay: 17s;
  animation-duration: 17s;
}
.circles li:nth-child(29) {
  left: 51%;
  width: 109px;
  height: 109px;
  background: rgb(55, 177, 147);
  animation-delay: 4s;
  animation-duration: 25s;
}
.circles li:nth-child(30) {
  left: 15%;
  width: 129px;
  height: 129px;
  animation-delay: 4s;
  animation-duration: 10s;
}

.text {
  /* font-family: "Mukta", sans-serif !important; */
  font-size: 8em !important;
  text-align: center;
  padding: none !important;
  display: none !important;
  color: #919191!important;
  margin-bottom: 40px;
  font-family: "open sans", sans-serif;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    -webkit-transform-delay: translateY(0) rotate(0deg);
    -moz-transform-delay: translateY(0) rotate(0deg);
    -ms-transform-delay: translateY(0) rotate(0deg);
    -o-transform-delay: translateY(0) rotate(0deg);

    opacity: 1;
    border-radius: 80%;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    -webkit-transform-delay: translateY(-1000px) rotate(720deg);
    -moz-transform-delay: translateY(-1000px) rotate(720deg);
    -ms-transform-delay: translateY(-1000px) rotate(720deg);
    -o-transform-delay: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 98%;
  }
}


#text path:nth-child(1) {
  stroke-dasharray: 1047px;
  stroke-dashoffset: 1047px;
  animation: line-animation ease forwards 1.2s, box-shadow 0.5s ease forwards 6s;
}

#text path:nth-child(2) {
  stroke-dasharray: 1084px;
  stroke-dashoffset: 1084px;
  animation: line-animation ease forwards 1.5s 0.5s,
    box-shadow 1s ease forwards 6s;
}

#text path:nth-child(3) {
  stroke-dasharray: 1046px;
  stroke-dashoffset: 1046px;
  animation: line-animation ease forwards 1.5s 1s,
    box-shadow 1.3s ease forwards 6s;
}

#text path:nth-child(4) {
  stroke-dasharray: 790px;
  stroke-dashoffset: 790px;
  animation: line-animation ease forwards 1.5s 1.5s,
    box-shadow 1.69s ease forwards 6s;
}
#text path:nth-child(5) {
  stroke-dasharray: 790px;
  stroke-dashoffset: 790px;
  animation: line-animation ease forwards 1.5s 2s,
    box-shadow 2.197s ease forwards 6s;
}
#text path:nth-child(6) {
  stroke-dasharray: 790px;
  stroke-dashoffset: 790px;
  animation: line-animation ease forwards 1.5s 2.5s,
    box-shadow 2.856s ease forwards 6s;
}
#text path:nth-child(7) {
  stroke-dasharray: 790px;
  stroke-dashoffset: 790px;
  animation: line-animation ease forwards 1.5s 3s,
    box-shadow 3.713s ease forwards 6s;
}
#text path:nth-child(8) {
  stroke-dasharray: 790px;
  stroke-dashoffset: 790px;
  animation: line-animation ease forwards 1.5s 3.5s,
    box-shadow 4.827s ease forwards 6s;
}
#text path:nth-child(9) {
  stroke-dasharray: 790px;
  stroke-dashoffset: 790px;
  animation: line-animation ease forwards 1.5s 4s,
    box-shadow 6.275s ease forwards 6s;
}
#text path:nth-child(10) {
  stroke-dasharray: 790px;
  stroke-dashoffset: 790px;
  animation: line-animation ease forwards 1.5s 4.5s,
    box-shadow 8.157s ease forwards 6s;
}

@keyframes line-animation {
  from {
    stroke-dasharray: 500px;
    stroke-dashoffset: 50%;
  }
  to {
    stroke-dashoffset: 0%;
  }
}

@keyframes box-shadow {
  to {
    stroke-width: 7;
    stroke-linecap: round;
    stroke: #919191;
    fill: #919191;
    fill-rule: evenodd;
  }
}
.st0 {
  display: none;
}
.st1 {
  display: inline;
  fill: #001745;
}
.st2 {
  display: inline;
}
.st3 {
  fill: none;
  stroke: #c0c0c0;
  stroke-width: 1;
  stroke-miterlimit: 0;
}

.triangle_down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid white;
  font-size: 0;
  line-height: 0;
  float: left;
}
.triangle_down1 {
  position: relative;
  top: 15em;
  content: "";
  display: inline-block;
  width: 45px;
  height: 45px;
  border-right: 0.2em solid white;
  border-top: 0.2em solid white;
  transform: rotate(135deg);
  visibility: hidden;
  /* animation: box-shadow 3s 6s ease-in forwards; */
  animation: jumpInfinite 1.5s 6s infinite;
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
    visibility: visible;
  }
  25% {
    margin-top: 10px;
    visibility: visible;
  }
  50% {
    margin-top: 20px;
    visibility: visible;
  }
  75% {
    margin-top: 10px;
    visibility: visible;
  }
  100% {
    margin-top: 0;
    visibility: visible;
  }
}

@media screen and (max-width: 1501px) {
  .triangle_down1 {
    top: 30em;
  }
  .home_banner_area {
    line-height: 5px !important;

  }
}

@media screen and (max-width: 1100px) {
  .svg {
    display: none !important;
  }
  .text {
    display: block !important;
    line-height: .9 !important;
  }
}

@media screen and (max-width: 901px) {
  .svg {
    width: 560px;
  }
  .triangle_down1 {
    top: 30em;
  }
}

@media screen and (max-width: 601px) {
  .svg {
    width: 450px;
  }
  .triangle_down1 {
    top: 30em;
  }
}
@media screen and (max-width: 450px) {
  .svg {
    width: 300px;
  }
  .triangle_down1 {
    top: 20em;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.catch-phrase {
  font-size: 1.8rem!important;
    line-height: 3.2rem!important;
    text-align: center;
    font-weight: 400!important;
    color: var(--fSfh3A)!important;
    font-family: "Open Sans", sans-serif!important;

}

.cover-img {
  width: 100%;
  height: 100%;

  object-fit: cover;
  border-radius: 15px;
  height:300px;
}

.cover-img-wrapper {
  padding-left: 10px!important;
  padding-right: 10px!important;
}

.la {
  vertical-align: middle!important;
}

.test {display: table-cell; vertical-align: middle;
}

.content1 div {
  margin-top:auto!important;
  margin-bottom:auto!important;
  text-align:center!important;
}