/* @import '~antd/dist/antd.css'; */

.margin-left-1 {
    margin-left: 1rem!important;
}

.margin-left-2 {
    margin-left: 2rem!important;
}

.margin-left-3 {
    margin-left: 3rem!important;
}

.margin-left-4 {
    margin-left: 4rem!important;
}

.margin-left-5 {
    margin-left: 5rem!important;
}

.margin-right-05 {
    margin-right: 0.5rem!important;
}

.margin-right-1 {
    margin-right: 1rem!important;
}

.margin-right-2 {
    margin-right: 2rem!important;
}

.margin-right-3 {
    margin-right: 3rem!important;
}

.margin-right-4 {
    margin-right: 4rem!important;
}

.margin-right-5 {
    margin-right: 5rem!important;
}

.margin-top-1 {
    margin-top: 1rem!important;
}

.margin-top-2 {
    margin-top: 2rem!important;
}

.margin-top-3 {
    margin-top: 3rem!important;
}

.margin-top-4 {
    margin-top: 4rem!important;
}

.margin-top-5 {
    margin-top: 5rem!important;
}

.margin-bottom-1 {
    margin-bottom: 1rem!important;
}

.margin-bottom-2 {
    margin-bottom: 2rem!important;
}

.margin-bottom-3 {
    margin-bottom: 3rem!important;
}

.margin-bottom-4 {
    margin-bottom: 4rem!important;
}

.margin-bottom-5 {
    margin-bottom: 5rem!important;
}

.margin-bottom-10 {
    margin-bottom: 5rem;
}

.no-text-center {
    text-align: left!important;
}

.br-50 {
    border-radius: 50%!important;
}

body {
    overflow-x: hidden;
}

.btn-primary-soft {

    color: #37B193;
    background: linear-gradient(to left, #37B193 50%, white 50%) left;
    background-size: 200%;
    transition: .4s ease-out;
    border: #37B193 1px solid;
}

.btn-primary-soft:hover {
    color: white;
    background: #37B193;
    border: #37B193 1px solid!important;
    color: white!important;
}

.btn-primary-soft-alt {

    color: #d76e1d;
    background: linear-gradient(to left, #d76e1d 50%, white 50%) left;
    background-size: 200%;
    transition: .4s ease-out;
    border: #d76e1d 1px solid;
}

.btn-primary-soft-alt:hover {
    color: white;
    background: #d76e1d;
    border: #d76e1d 1px solid!important;
    color: white!important;
}

.btn-primary-soft-inverse {

    color: white;
    background: #37B193;
    background-size: 200%;
    transition: .4s ease-out;
    border: #37B193 1px solid;
}

.btn-primary-soft-inverse:hover {
    color: white;
    background: #d76e1d;
    border: #d76e1d 1px solid!important;
    color: white!important;
}

@media screen and (max-width: 768px) {
    .max-width-0 {
        max-width: 100% !important;
      }
}