@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.ml-4 {
    margin-left: 3rem;
}

.contestHeader {
    font-family: 'Open Sans', sans-serif;
    color: #000000;
    font-weight: 800;
}

.results {
    color: #FF904D;
}